import {useCallback, useEffect, useState} from "react";
import {removeUndefined, stringifyValues} from "./utils/utils";
import {useSearchParams} from 'react-router-dom';

const STORAGE_KEY_PREFIX = 'ksir.accounts';
const STORAGE_EVENT = 'ksir.accounts.local-storage';

export const useLocalStorage = (key, defaultValue) => {
    const storageKey = `${STORAGE_KEY_PREFIX}.${key}`;
    const [value, setValue] = useState(localStorage.getItem(storageKey) || defaultValue);

    const setter = useCallback((value) => {
        if (value !== null) {
            localStorage.setItem(storageKey, value);
        } else {
            localStorage.removeItem(storageKey);
        }
        window.dispatchEvent(new CustomEvent(STORAGE_EVENT, {detail: {key: storageKey, value: value}}));
    }, [storageKey]);

    useEffect(() => {
        const handleStorageEvent = e => {
            if (e.detail.key === storageKey) {
                setValue(e.detail.value);
            }
        };
        window.addEventListener(STORAGE_EVENT, handleStorageEvent);
        return () => {
            window.removeEventListener(STORAGE_EVENT, handleStorageEvent);
        };
    }, [storageKey]);

    return [value, setter];
};

export const useUrlParams = () => {
    const [urlParams, setSearchParams] = useSearchParams();

    const setUrlParams = params => {
        const allParams = {};
        for (const [key, val] of urlParams) {
            allParams[key] = val;
        }
        for (const [key, val] of Object.entries(params)) {
            allParams[key] = val;
        }
        replaceUrlParams(allParams);
    };

    const replaceUrlParams = (params, push = true) => {
        removeUndefined(params);
        stringifyValues(params);
        const newParams = new URLSearchParams();
        for (const [key, val] of Object.entries(params)) {
            newParams.set(key, val);
        }
        console.log('[useUrlParams].replaceUrlParams', 'urlParams:', urlParams.toString(), 'newParams:', newParams.toString(), 'push:', push);
        setSearchParams(newParams, {replace: !push});
    };

    return [urlParams, setUrlParams, replaceUrlParams];
};
