import React, {useState} from "react";
import PropTypes from "prop-types";

function EditToggle(props) {

    const [isEditMode, setIsEditMode] = useState(false);
    const toggleIsEditMode = () => setIsEditMode(!isEditMode);

    if (isEditMode) {
        const onChangeAction = () => {
            toggleIsEditMode();
            props.editComponent.props.onChangeAction();
        };
        return React.cloneElement(props.editComponent,
            {
                cancelButtonAction: toggleIsEditMode,
                onChangeAction: onChangeAction
            }
        );
    } else {
        return React.cloneElement(props.readOnlyComponent,
            {editButtonAction: toggleIsEditMode}
        );
    }

}

EditToggle.propTypes = {
    editComponent: PropTypes.element.isRequired,
    readOnlyComponent: PropTypes.element.isRequired,
};

export default EditToggle;
