import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import * as PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    chip: {
        marginLeft: theme.spacing(1)
    }
}));

function MultiSelectChips(props) {
    const classes = useStyles();

    const handleChangeMultiSelect = (event) => {
        props.handleChangeMultiSelect(event.target.value);
    };

    const getItemFromId = (selectedId) => {
        return props.availableItems.find(x => x.id === selectedId) || {id: selectedId, name: "Unknown", pictureUrl: ""};
    };

    const handleDelete = (value) => {
        props.handleChangeMultiSelect(props.preSelectedIds.filter(id => id !== value));
    };

    return <FormControl fullWidth>
        <InputLabel>{props.inputTitle}</InputLabel>
        <Select
            multiple
            MenuProps={{
                getContentAnchorEl: undefined,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }
            }}
            value={props.preSelectedIds ? props.preSelectedIds : []}
            onChange={handleChangeMultiSelect}
            renderValue={selected => (
                <div>
                    {selected
                        .map(selectedId => getItemFromId(selectedId))
                        .map(item => (
                            <Chip key={item.id}
                                  variant={'outlined'}
                                  avatar={item.pictureUrl ? <Avatar alt={item.name} src={item.pictureUrl}/> : undefined}
                                  label={item.name}
                                  className={classes.chip}
                                  deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()}/>}
                                  onDelete={() => handleDelete(item.id)}
                            />
                        ))}
                </div>
            )}
        >
            {props.availableItems.map(item =>
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
            )}
        </Select>
    </FormControl>;
}

MultiSelectChips.propTypes = {
    inputTitle: PropTypes.string.isRequired,
    availableItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        pictureUrl: PropTypes.string
    })).isRequired,
    handleChangeMultiSelect: PropTypes.func.isRequired,
    preSelectedIds: PropTypes.arrayOf(PropTypes.number)
};

export default MultiSelectChips;