import {format as formatDate, parse as parseDate, toDate} from "date-fns";

const ccyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
});

const roundedCcyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
});

export function formatCcy(amount) {
    return ccyFormatter.format(amount);
}

export function formatRoundedCcy(amount) {
    return roundedCcyFormatter.format(amount);
}

export function formatCcyDefault(amount, def) {
    return amount === 0 ? def : ccyFormatter.format(amount);
}

export function currentEpochSecs() {
    return Math.floor(new Date().getTime() / 1000);
}

const VALID_MONTHS = Array.from({length: 12}, (x, i) => padMonth(i + 1));

export function validDate(year, month) {
    return month !== null && year !== null
        && VALID_MONTHS.indexOf(String(month)) !== -1
        && parseInt(year) > 1990
        && parseInt(year) <= new Date().getFullYear();
}

export function padMonth(month) {
    return String(month).padStart(2, '0');
}

export function jsMonthOf(month) {
    return VALID_MONTHS.indexOf(String(month));
}

export function formatYearMonth(ym) {
    return formatDate(parseDate(ym, 'yyyy-MM', toDate(0)), "MMM ''yy");
}

export function formatMonth(m) {
    return formatDate(parseDate(m, 'MM', toDate(0)), "MMM");
}

export function currentMonthYear() {
    return {year: new Date().getFullYear(), month: new Date().getMonth()};
}

// Copied from recharts/es6/util/PureRender#shallowEqual
export function shallowEqual(a, b) {
    /* eslint-disable no-restricted-syntax */
    for (var key in a) {
        if ({}.hasOwnProperty.call(a, key) && (!{}.hasOwnProperty.call(b, key) || a[key] !== b[key])) {
            return false;
        }
    }

    for (var _key in b) {
        if ({}.hasOwnProperty.call(b, _key) && !{}.hasOwnProperty.call(a, _key)) {
            return false;
        }
    }

    return true;
}

export const toMapById = list => {
    return list.reduce((map, x) => {
        map[x.id] = x;
        return map;
    }, {});
};

export function removeUndefined(obj) {
    Object.entries(obj).forEach(([key, val]) => {
        if (val === undefined) {
            delete obj[key];
        }
    });
    return obj;
}

export function stringifyValues(obj) {
    Object.entries(obj).forEach(([key, val]) => {
        obj[key] = String(val);
    });
}

// Copied from https://stackoverflow.com/a/3561711
export function escapeRegex(s) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function changeTitle(newTitle) {
    const separator = ' :: ';
    document.title = document.title.split(separator)[0] + `${separator}${newTitle}`;
}