import {makeStyles} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {escapeRegex, formatCcy} from "../utils/utils";
import {saveRule} from "../Rules/RulesApi";
import Typography from "@material-ui/core/Typography/Typography";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Grid from "@material-ui/core/Grid/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import AmountInput from "../UtilComponents/AmountInput";
import CategoryPicker from "./CategoryPicker";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.error.main,
        margin: theme.spacing(0.5),
    },
    ruleDescription: {
        marginLeft: theme.spacing(2),
    },
}));

function AddRuleDialog(props) {

    const classes = useStyles();

    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [amountMin, setAmountMin] = useState(0);
    const [amountMax, setAmountMax] = useState(0);
    const [pattern, setPattern] = useState("");
    const [categoryId, setCategoryId] = useState(0);

    useEffect(() => setPattern(escapeRegex(props.txnDescription)), [props.txnDescription]);
    const timer = useRef(null);
    useEffect(() => () => clearTimeout(timer.current), []);

    const isInvalid = pattern.trim().length === 0 || amountMin < 0 || amountMax < amountMin || !categoryId;

    const createRule = () => {
        setIsSaving(true);
        saveRule({amountMin, amountMax, pattern, categoryId})
            .then(
                () => {
                    setIsSaving(false);
                    props.onClose();
                    setPattern("");
                    setAmountMin(0);
                    setAmountMax(0);
                    setCategoryId(0);
                    timer.current = setTimeout(() => {
                        props.refreshTxnTable();
                    }, 500);
                }
            )
            .catch(error => setError(error.message));
    };


    if (error) {
        return <Typography className={classes.warning}>{error}</Typography>;
    }

    return (
        <Dialog maxWidth="sm"
                open={props.isDialogOpen}
                onClose={props.onClose}>
            <DialogTitle>Create Rule
                <Typography className={classes.ruleDescription} component="span"
                            variant="body2">
                    {props.txnDescription} {formatCcy(props.txnAmount / 100)}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField label="Pattern"
                                       value={pattern}
                                       onChange={e => setPattern(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <AmountInput value={amountMin} onChange={setAmountMin} label={"Min Amount"}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <AmountInput value={amountMax} onChange={setAmountMax} label={"Max Amount"}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryPicker categoryPickerId={"add-rule-dialog"}
                                        availableCategories={props.categories}
                                        onSave={setCategoryId}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    Cancel
                </Button>
                <Button disabled={isInvalid || isSaving} onClick={createRule} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddRuleDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    txnDescription: PropTypes.string.isRequired,
    txnAmount: PropTypes.number.isRequired,
    categories: PropTypes.object.isRequired,
    refreshTxnTable: PropTypes.func.isRequired,
};

export default AddRuleDialog;