import * as PropTypes from "prop-types";

function Optional(props) {
    return !props.hidden && props.children;
}

Optional.propTypes = {
    hidden: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export default Optional;
