import React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddIcon from '@material-ui/icons/Add';
import PropTypes from "prop-types";
import EditToggle from "./EditToggle";

const AddComponentButton = props => (
    <IconButton onClick={props.editButtonAction ? props.editButtonAction : () => {}}>
        <AddIcon/>
    </IconButton>
);
AddComponentButton.propTypes = {
    editButtonAction: PropTypes.func,
};

const AddComponentToggle = props => (
    <EditToggle editComponent={props.componentToAdd}
                readOnlyComponent={<AddComponentButton/>}/>
);

AddComponentToggle.propTypes = {
    componentToAdd: PropTypes.element.isRequired,
    editButtonAction: PropTypes.func,
};

export default AddComponentToggle;
