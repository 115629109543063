import React, {Component} from "react";
import API from '../api';
import {PATH_CATEGORIES} from "../config";
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Select from "@material-ui/core/Select/Select";
import Typography from "@material-ui/core/Typography/Typography";
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import ControlButtons from "../UtilComponents/ControlButtons";
import CardActions from "@material-ui/core/CardActions/CardActions";

const styles = theme => ({
    card: {
        backgroundColor: theme.palette.background.default,
    },
    warning: {
        color: theme.palette.error.main,
    },
});

class EditableCategoryCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryName: this.props.categoryName,
            discard: this.props.discard,
            parentId: this.props.parentId,
            availableCategories: [{
                "id": 0,
                "name": "None",
                "discard": false,
                "parentId": 0,
                "children": []
            }].concat(this.props.parentCategories.filter(c => c.id !== this.props.categoryId)),
            isSaving: false,
            isDeleting: false,
            error: undefined,
        };
    }

    save() {
        this.setState({isSaving: true});

        const data = {
            "name": this.state.categoryName,
            "discard": this.state.discard,
            "parentId": this.state.parentId
        };

        const axiosPromise = this.props.categoryId
            ? API.put(`${PATH_CATEGORIES}/${this.props.categoryId}`, data)
            : API.post(PATH_CATEGORIES, data);

        const that = this;
        axiosPromise
            .then(() => that.props.onChangeAction())
            .catch(error => that.setState({error}));
    }

    delete() {
        this.setState({isDeleting: true});

        let axiosPromise = API.delete(PATH_CATEGORIES + "/" + this.props.categoryId);

        const that = this;
        axiosPromise
            .then(() => that.props.onChangeAction())
            .catch(error => that.setState({error}));
    }

    cancel() {
        this.props.cancelButtonAction();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleToggle = () => event => {
        this.setState({
            discard: event.target.checked,
        });
    };

    render() {
        const {classes} = this.props;
        if (this.state.error) {
            return (
                <Card className={classes.card}>
                    <CardHeader className={classes.cardPadding}
                                title={this.props.categoryName}
                                titleTypographyProps={{variant: "subtitle1"}}
                    />
                    <CardContent className={classes.warning}>
                        <Typography>{this.state.error.message}</Typography>
                    </CardContent>
                </Card>
            );
        }
        return (
            <Card className={classes.card}>
                <CardContent className={classes.cardPadding}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {this.showCategoryNameInput()}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.showParentCategorySelect()}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {this.showDiscardCheckbox()}
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <ControlButtons onClickSave={() => this.save()}
                                    onClickCancel={() => this.cancel()}
                                    onClickDelete={() => this.delete()}
                                    isSaveDisabled={this.state.isSaving}
                                    isDeleteDisabled={this.props.categoryId === 0 || this.state.isDeleting || this.props.hasSubCategories}
                    />
                </CardActions>
            </Card>
        );
    }

    showCategoryNameInput() {
        return (
            <FormControl fullWidth>
                <InputLabel>Category Name</InputLabel>
                <Input
                    value={this.state.categoryName}
                    onChange={this.handleChange('categoryName')}
                />
            </FormControl>
        );
    }

    showParentCategorySelect() {
        return (
            <FormControl fullWidth disabled={this.props.hasSubCategories}>
                <InputLabel>Parent Category</InputLabel>
                <Select
                    value={this.state.parentId ? this.state.parentId : this.state.availableCategories[0].id}
                    onChange={this.handleChange('parentId')}>
                    {this.state.availableCategories.map(category =>
                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    }

    showDiscardCheckbox() {
        return (
            <FormControl fullWidth>
                <FormControlLabel
                    control={<Checkbox
                        checked={this.state.discard}
                        onChange={this.handleToggle('discard')}
                    />}
                    label="Discard"
                />
            </FormControl>
        );
    }
}

EditableCategoryCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    parentCategories: PropTypes.array.isRequired,
    categoryId: PropTypes.number.isRequired,
    categoryName: PropTypes.string.isRequired,
    discard: PropTypes.bool.isRequired,
    parentId: PropTypes.number.isRequired,
    hasSubCategories: PropTypes.bool.isRequired,
    onChangeAction: PropTypes.func.isRequired,
    cancelButtonAction: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(EditableCategoryCard);
