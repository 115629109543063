import React, {useEffect, useMemo} from "react";
import {useDataApi} from '../api';
import {PATH_ACCOUNTS} from "../config";
import AddComponentToggle from "../UtilComponents/AddComponentToggle";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography";
import EditToggle from "../UtilComponents/EditToggle";
import EditableAccountCard from "./EditableAccountCard";
import ReadOnlyAccountCard from "./ReadOnlyAccountCard";
import {changeTitle} from "../utils/utils";

function Accounts() {
    useEffect(() => changeTitle('Accounts'), []);

    const [accountsState, refresh] = useDataApi(PATH_ACCOUNTS, {accounts: []});
    const accounts = useMemo(() => accountsState.data.accounts, [accountsState.data.accounts]);

    if (accountsState.isLoading) {
        return <Typography>Loading ...</Typography>;
    }

    if (accountsState.error) {
        return <Typography>Could not load accounts. {accountsState.error}</Typography>;
    }
    if (accounts.length === 0) {
        return <Typography>No Accounts</Typography>;
    }

    return <>
        <Typography variant="h4" gutterBottom>Accounts</Typography>
        <Grid container spacing={3}>
            {accountsState.data.accounts.map(account =>
                <Grid item xs={12} md={6} lg={4} key={account.id}>
                    <EditToggle
                        editComponent={<EditableAccountCard account={account}
                                                            userIds={account.users.map(user => user.id)}
                                                            onChangeAction={refresh}/>}
                        readOnlyComponent={<ReadOnlyAccountCard account={account}/>}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={6} lg={4}>
                <AddComponentToggle componentToAdd={<EditableAccountCard onChangeAction={refresh}/>}/>
            </Grid>
        </Grid>
    </>;
}

export default Accounts;
