import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography";
import {format as formatDate} from "date-fns";

const styles = theme => ({
    typography: {
        padding: theme.spacing(1),
    },
    error: {
        padding: theme.spacing(1),
        color: theme.palette.error.main,
    },
    gridItem: {
        marginBottom: theme.spacing(2),
    },
});

class JobsProgress extends Component {

    render() {
        const {classes} = this.props;

        return (
            <Grid item sm={12} className={classes.gridItem}>
                <Typography className={classes.typography}>{this.props.description}</Typography>
                {this.props.error ? this.getError() : null}
                <Grid container direction="row">
                    <Grid item sm={9}>
                        <Grid container direction="row">
                            <Grid item sm>
                                <Typography className={classes.typography}>
                                    Submitted: {formatDate(new Date(this.props.submittedTsMs), "HH:mm:ss")}
                                </Typography>
                            </Grid>
                            <Grid item sm>
                                <Typography className={classes.typography}>
                                    Started: {formatDate(new Date(this.props.startedTsMs), "HH:mm:ss")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        {this.props.finishedTsMs ? this.getDuration() : null}
                    </Grid>
                </Grid>
                <LinearProgress variant="determinate" value={this.props.progressPct}/>
            </Grid>
        );

    }

    getError() {
        const {classes} = this.props;
        return <Typography className={classes.error}>{this.props.error}</Typography>;
    }

    getDuration() {
        const {classes} = this.props;
        return <Typography className={classes.typography} align="right">
            {((this.props.finishedTsMs - this.props.startedTsMs) / 1000).toFixed(3) + "s"}
        </Typography>;
    }
}

JobsProgress.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    submittedTsMs: PropTypes.number.isRequired,
    startedTsMs: PropTypes.number.isRequired,
    finishedTsMs: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    progressPct: PropTypes.number.isRequired,
    error: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(JobsProgress);
