import React, {useEffect, useMemo, useState} from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper";
import MonthPicker from "../MonthPicker/MonthPicker";
import TransactionsTable from "./TransactionsTable";
import IconButton from "@material-ui/core/IconButton";
import {changeTitle, currentMonthYear, padMonth, toMapById, validDate} from "../utils/utils";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import {makeStyles} from "@material-ui/core";
import * as PropTypes from "prop-types";
import Optional from "../UtilComponents/Optional";
import {useLocalStorage, useUrlParams} from "../Hooks";
import Button from "@material-ui/core/Button/Button";
import {useDataApi} from "../api";
import {PATH_ACCOUNTS_MINE, PATH_CATEGORIES} from "../config";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import RefreshIcon from '@material-ui/icons/Refresh';
import {flattenCategories} from "../utils/categories";
import SearchBox from "../UtilComponents/SearchBox";
import {Navigate} from "react-router";
import {useLocation} from "react-router-dom";
import MultiSelectChips from "../UtilComponents/MultiSelectChips";
import {formatIdsForURL, getAccountsWithImage, getIdsArrayFromURLString} from "../utils/url-search";
import AddRuleDialog from "./AddRuleDialog";
import {CREDIT_DEBIT} from "../LoginInfo";

const useMissingCatStyles = makeStyles(theme => ({
    filter: {
        marginRight: theme.spacing(2),
        verticalAlign: 'text-bottom',
    },
}));

function MissingCategoriesOnlySwitch(props) {
    const classes = useMissingCatStyles();

    const onChange = event => props.onChange(event.target.checked);

    return <FormControlLabel label="Missing categories only" className={classes.filter}
                             control={
                                 <Switch color="primary" checked={props.checked} onChange={onChange}
                                 />
                             }/>;
}

MissingCategoriesOnlySwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    transactionsHeader: {
        marginBottom: theme.spacing(0.2),
    },
    heading: {
        display: 'inline',
    },
    select: {
        minWidth: theme.spacing(15),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    multiSetButton: {
        textTransform: "none",
        marginRight: theme.spacing(1.5),
    },
}));


Transactions.defaultParams = (extra = {}) => {
    const monthYear = currentMonthYear();
    let year = monthYear.year;
    let month = padMonth(monthYear.month);
    if (month === "00") {
        month = "12";
        year--;
    }
    return {...extra, year, month};
};

function Transactions() {
    useEffect(() => changeTitle('Transactions'), []);

    const classes = useStyles();

    const location = useLocation();
    const [urlParams, setUrlParams, replaceUrlParams] = useUrlParams();
    const [categoriesState] = useDataApi(PATH_CATEGORIES, {categories: []});
    const [accountsState] = useDataApi(PATH_ACCOUNTS_MINE, {accounts: []});
    const [creditDebit, _] = useLocalStorage('transactions.credit_debit', CREDIT_DEBIT.BOTH);
    const [isAddRuleDialogOpen, setIsAddRuleDialogOpen] = useState(false);
    const [isMultiSetCategoryDialogOpen, setIsMultiSetCategoryDialogOpen] = useState(false);
    const [addRuleTxn, setAddRuleTxn] = useState({txnDescription: "", txnAmount: 0});
    const [cacheBuster, setCacheBuster] = useState(() => new Date().getTime());
    const [multiSelectMode, setMultiSelectMode] = useState(false);

    const invalidUrlParams = !validAccounts(urlParams) || !validCategories(urlParams) || (!urlParams.get('search') && !validDate(urlParams.get('year'), urlParams.get('month')));

    const userChoiceCategories = useMemo(() => [{
        id: 0,
        name: "Missing Category"
    }].concat(flattenCategories(categoriesState.data.categories)), [categoriesState.data.categories]);
    const categoriesById = useMemo(() => toMapById(flattenCategories(categoriesState.data.categories)), [categoriesState.data.categories]);
    const userAccounts = useMemo(() => getAccountsWithImage(accountsState.data.accounts), [accountsState.data.accounts]);

    const handleMonthPickerChanged = (year, month) => {
        setUrlParams({
            year: year,
            month: month,
            search: undefined,
        });
    };

    const handleSearchChanged = value => {
        replaceUrlParams({
            search: value,
            accounts: urlParams.has('accounts') ? urlParams.get('accounts') : undefined,
        });
    };

    const handleSearchCleared = () => {
        replaceUrlParams(Transactions.defaultParams({accounts: urlParams.has('accounts') ? urlParams.get('accounts') : undefined}));
    };

    const openAddRuleDialog = (txnDescription, txnAmount) => {
        setIsAddRuleDialogOpen(true);
        setAddRuleTxn({txnDescription, txnAmount});
    };

    const handleAccountsSelectChanged = (accountIds) => {
        setUrlParams({
            ...urlParams,
            accounts: accountIds.length === 0 ? undefined : formatIdsForURL(accountIds),
        });
    };

    const handleCategoriesSelectChanged = (categoryIds) => {
        setUrlParams({
            ...urlParams,
            categories: categoryIds.length === 0 ? undefined : formatIdsForURL(categoryIds),
        });
    };

    function showCategoriesSelect(urlProps) {
        return !urlProps.has('search');
    }

    function showSearchBox(urlProps) {
        return !urlProps.has('categories'); // TODO: When should this be disabled?
    }

    function showMonthPicker(urlProps) {
        return !urlProps.has('search');
    }

    function validAccounts(urlProps) {
        return !urlProps.has('accounts') || (getIdsArrayFromURLString(urlParams.get('accounts')).length !== 0 && getIdsArrayFromURLString(urlParams.get('accounts')).filter(x => x <= 0).length === 0);
    }

    function validCategories(urlProps) {
        return !urlProps.has('categories') || (getIdsArrayFromURLString(urlParams.get('categories')).length !== 0 && getIdsArrayFromURLString(urlParams.get('categories')).filter(x => x < 0).length === 0);
    }

    const refreshTransactionsTable = () => setCacheBuster(new Date().getTime());

    if (invalidUrlParams) {
        console.log('[render]', 'Invalid params:', urlParams.toString(), 'Rendering navigate to default');
        const defaultParamsString = new URLSearchParams(Transactions.defaultParams()).toString();
        return <Navigate to={`${location.pathname}?${defaultParamsString}`} replace/>;
    }

    if (categoriesState.isLoading) {
        return <LinearProgress/>;
    }

    if (categoriesState.error) {
        return <Typography className={classes.error} variant="h6">{categoriesState.error}</Typography>;
    }

    console.log('[render]', 'rendering actual content');
    return <>
        <Grid container direction="row" justifyContent="space-between" className={classes.transactionsHeader}>
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Typography variant="h4" className={classes.heading}>Transactions</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={refreshTransactionsTable} color="primary">
                            <RefreshIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item>
                        <Optional hidden={!multiSelectMode}>
                            <Button variant="outlined" className={classes.multiSetButton} color={"primary"}
                                    onClick={() => {
                                        setIsMultiSetCategoryDialogOpen(true);
                                    }}>
                                <Typography>Multi Set Category</Typography>
                            </Button>
                        </Optional>
                    </Grid>
                    <Optional hidden={!showSearchBox(urlParams)}><Grid item>
                        <SearchBox
                            value={urlParams.get('search') || ''}
                            onKeyDown={handleSearchChanged}
                            onClear={handleSearchCleared}/>
                    </Grid></Optional>
                    <Grid item className={classes.select}>
                        <MultiSelectChips inputTitle="Accounts"
                                          availableItems={userAccounts}
                                          handleChangeMultiSelect={handleAccountsSelectChanged}
                                          preSelectedIds={urlParams.has('accounts') ? getIdsArrayFromURLString(urlParams.get('accounts')) : undefined}/>
                    </Grid>
                    <Optional hidden={!showCategoriesSelect(urlParams)}>
                        <Grid item className={classes.select}>
                            <MultiSelectChips inputTitle="Categories"
                                              availableItems={userChoiceCategories}
                                              handleChangeMultiSelect={handleCategoriesSelectChanged}
                                              preSelectedIds={urlParams.has('categories') ? getIdsArrayFromURLString(urlParams.get('categories')) : undefined}/>
                        </Grid></Optional>
                    <Optional hidden={!showMonthPicker(urlParams)}><Grid item>
                        <MonthPicker from={new Date(2012, 7)} to={new Date()}
                                     year={parseInt(urlParams.get('year'))} month={urlParams.get('month')}
                                     onChange={handleMonthPickerChanged}/>
                    </Grid></Optional>
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={11} lg={9} xl={6}>
                <Paper elevation={2}>
                    <TransactionsTable
                        yearMonth={urlParams.has('search') ? '' : urlParams.get('year') + "-" + urlParams.get('month')}
                        search={urlParams.get('search')}
                        categoryIds={urlParams.has('categories') ? getIdsArrayFromURLString(urlParams.get('categories')) : undefined}
                        accountIds={urlParams.has('accounts') ? getIdsArrayFromURLString(urlParams.get('accounts')) : undefined}
                        creditDebit={creditDebit}
                        openAddRuleDialog={openAddRuleDialog}
                        categories={categoriesById}
                        cacheBuster={cacheBuster}
                        isMultiSelectMode={multiSelectMode}
                        onAnyCheckboxesSelected={setMultiSelectMode}
                        isMultiSetCategoryDialogOpen={isMultiSetCategoryDialogOpen}
                        onMultiSetCategoryDialogClose={() => setIsMultiSetCategoryDialogOpen(false)}
                        refreshTransactionsTable={refreshTransactionsTable}
                    />
                </Paper>
            </Grid>
        </Grid>
        <AddRuleDialog isDialogOpen={isAddRuleDialogOpen}
                       onClose={() => setIsAddRuleDialogOpen(false)}
                       {...addRuleTxn}
                       categories={categoriesById}
                       refreshTxnTable={refreshTransactionsTable}/>
    </>;
}

export default Transactions;
