import React, {useEffect, useMemo, useState} from 'react';
import {useDataApi} from "../api";
import {PATH_CATEGORIES, PATH_RULES} from "../config";
import Typography from "@material-ui/core/Typography";
import CategoriesRules from "./CategoriesRules";
import {Accordion, AccordionDetails, AccordionSummary, makeStyles} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid/Grid";
import SearchBox from "../UtilComponents/SearchBox";
import {sortAndFilterCategoriesBySearchInRulePattern} from "../utils/categories";
import {changeTitle} from "../utils/utils";

const useStyles = makeStyles(() => ({
    expansionHeading: {
        fontSize: "1.2rem",
    }
}));

Rules.getCategoryIdToRuleMap = (rules) => {
    return rules.reduce((rulesMap, rule) => {
        rulesMap[rule.categoryId] = rulesMap[rule.categoryId] || [];
        rulesMap[rule.categoryId].push(rule);
        return rulesMap;
    }, {});
};

function Rules() {
    useEffect(() => changeTitle('Rules'), []);

    const classes = useStyles();
    const [categoriesState] = useDataApi(PATH_CATEGORIES, {categories: []});
    const [rulesState] = useDataApi(PATH_RULES, {rules: []});
    const [expandedCategoryId, setExpandedCategoryId] = useState(undefined);
    const [searchValue, setSearchValue] = useState("");

    const categories = useMemo(() => sortAndFilterCategoriesBySearchInRulePattern(categoriesState.data.categories, rulesState.data.rules, searchValue), [categoriesState.data.categories, rulesState.data.rules, searchValue]);
    const categoryIdToRuleMap = useMemo(() => Rules.getCategoryIdToRuleMap(rulesState.data.rules), [rulesState.data.rules]);

    const handleExpansionPanelChange = id => (event, expanded) => {
        setExpandedCategoryId(expanded ? id : undefined);
    };

    const handleSearchChanged = (value) => {
        setSearchValue(value.toLowerCase());
    };

    const handleSearchCleared = () => {
        setSearchValue("");
    };

    if (rulesState.error || categoriesState.error) {
        return <Typography variant="subtitle1">
            {rulesState.error ? rulesState.error.message : categoriesState.error.message}
        </Typography>;
    }

    if (rulesState.isLoading || categoriesState.isLoading) {
        return <Typography variant="subtitle1">Loading ...</Typography>;
    }

    const showRules = () => {
        if (categories.length === 0) {
            return (
                <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h5">No Rules</Typography>
                </Grid>
            );
        }
        return categories.map(category =>
            <Accordion key={category.id}
                       expanded={expandedCategoryId === category.id}
                       onChange={handleExpansionPanelChange(category.id)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant="h5" className={classes.expansionHeading}>{category.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CategoriesRules
                        category={category}
                        categoryIdToRuleMap={categoryIdToRuleMap}
                        onChangeAction={() => this.reload()}
                        searchValue={searchValue}
                    />
                </AccordionDetails>
            </Accordion>
        );
    };

    return <>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <Typography variant="h4" gutterBottom>Rules</Typography>
            </Grid>
            <Grid item>
                <SearchBox
                    value={searchValue}
                    onKeyUp={handleSearchChanged}
                    onClear={handleSearchCleared}/>
            </Grid>
        </Grid>
        {showRules()}
    </>;
}

export default Rules;
