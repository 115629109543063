import React, {Component} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Grid from "@material-ui/core/Grid";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {formatMonth, formatYearMonth} from "../utils/utils";

const styles = () => ({
    monthPicker: {
        display: "inline-flex"
    },
    controls: {
        display: "flex",
        alignItems: "center"
    }
});

class MonthPicker extends Component {

    constructor(props) {
        super(props);

        this.availableYears = Array.from({length: props.to.getFullYear() - props.from.getFullYear() + 1}, (x, i) => i + props.from.getFullYear());
        this.allMonths = Array.from({length: 12}, (x, i) => String(i + 1).padStart(2, '0'));
        this.state = {
            open: false,
            year: props.year,
            month: props.month,
            newYear: parseInt(props.year),
        };
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.year !== this.props.year || prevState.month !== this.props.month) {
            this.setState({
                year: this.props.year,
                month: this.props.month,
                newYear: this.props.year,
            });
        }
    }

    findYearVariant(year) {
        if (year === this.state.newYear) {
            return "contained";
        } else if (year === parseInt(this.state.year)) {
            return "outlined";
        } else {
            return "text";
        }
    }

    findYearColor(year) {
        return year === this.state.newYear || year === this.state.year ? "primary" : "default";
    }

    findMonthVariant(month) {
        return this.state.year === this.state.newYear && month === this.state.month ? "contained" : "text";
    }

    findMonthColor(month) {
        return this.state.year === this.state.newYear && month === this.state.month ? "primary" : "default";
    }

    isMonthDisabled(month) {
        return (this.props.to.getFullYear() === this.state.newYear && this.allMonths.indexOf(month) > this.props.to.getMonth()) ||
            (this.props.from.getFullYear() === this.state.newYear && this.allMonths.indexOf(month) < this.props.from.getMonth());
    }

    handleClickOpen() {
        this.setState({open: true, newYear: this.state.year});
    }

    handleClose() {
        this.setState({open: false});
    }

    handleYearSelected(year) {
        this.setState({
            newYear: year
        });
    }

    handleMonthSelected(month) {
        this.setState({
            year: this.state.newYear,
            month: month,
            open: false
        });
        this.props.onChange(this.state.newYear, month);
    }

    isPreviousEnabled() {
        return new Date(this.props.from.getFullYear(), this.props.from.getMonth()).getTime() < new Date(this.state.year, this.allMonths.indexOf(this.state.month));
    }

    isNextEnabled() {
        return new Date(this.props.to.getFullYear(), this.props.to.getMonth()).getTime() > new Date(this.state.year, this.allMonths.indexOf(this.state.month));
    }

    handlePreviousClicked() {
        let newYear = this.state.month === this.allMonths[0] ? this.state.year - 1 : this.state.year;
        let newMonth = this.allMonths[(this.allMonths.length + this.allMonths.indexOf(this.state.month) - 1) % this.allMonths.length];
        this.setState({
            year: newYear,
            month: newMonth,
        });
        this.props.onChange(newYear, newMonth);
    }

    handleNextClicked() {
        let newYear = this.state.month === this.allMonths[this.allMonths.length - 1] ? this.state.year + 1 : this.state.year;
        let newMonth = this.allMonths[(this.allMonths.length + this.allMonths.indexOf(this.state.month) + 1) % this.allMonths.length];
        this.setState({
            year: newYear,
            month: newMonth,
        });
        this.props.onChange(newYear, newMonth);
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.monthPicker}>
                <div className={classes.controls}>
                    <IconButton color="primary" disabled={!this.isPreviousEnabled()}
                                onClick={() => this.handlePreviousClicked()}>
                        <ChevronLeft/>
                    </IconButton>
                    <Button
                        onClick={() => this.handleClickOpen()}>{formatYearMonth(`${this.state.year}-${this.state.month}`)}</Button>
                    <IconButton color="primary" disabled={!this.isNextEnabled()}
                                onClick={() => this.handleNextClicked()}>
                        <ChevronRight/>
                    </IconButton>
                </div>
                <Dialog open={this.state.open}
                        onClose={() => this.handleClose()}>
                    <DialogTitle id="form-dialog-title">Select Year and Month</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={6}>
                                <ImageList cols={4} rowHeight="auto">
                                    {this.availableYears.map(year => <ImageListItem key={year}
                                                                                   cols={1}><Button
                                        variant={this.findYearVariant(year)}
                                        color={this.findYearColor(year)}
                                        onClick={() => this.handleYearSelected(year)}>{year}</Button></ImageListItem>)}
                                </ImageList>
                            </Grid>
                            <Grid item xs={6}>
                                <ImageList cols={4} rowHeight="auto">
                                    {this.allMonths.map(month => <ImageListItem key={month}
                                                                               cols={1}><Button
                                        variant={this.findMonthVariant(month)}
                                        color={this.findMonthColor(month)}
                                        disabled={this.isMonthDisabled(month)}
                                        onClick={() => this.handleMonthSelected(month)}>{formatMonth(month)}</Button></ImageListItem>)}
                                </ImageList>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

MonthPicker.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    year: PropTypes.number.isRequired,
    month: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(MonthPicker);
