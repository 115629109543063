import React, {useEffect, useMemo, useState} from "react";
import {useDataApi} from '../api';
import {PATH_CATEGORIES} from "../config";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import AddComponentToggle from "../UtilComponents/AddComponentToggle";
import EditableCategoryCard from "./EditableCategoryCard";
import EditToggle from "../UtilComponents/EditToggle";
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AccordionDetails, AccordionSummary, makeStyles} from "@material-ui/core";
import ReadOnlyCategoryCard from "./ReadOnlyCategoryCard";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import SearchBox from "../UtilComponents/SearchBox";
import Highlight from "../UtilComponents/Highlight";
import {sortAndFilterCategoriesBySearchInCategoryName} from "../utils/categories";
import {changeTitle} from "../utils/utils";

const useStyles = makeStyles(() => ({
    expansionHeading: {
        fontSize: "1.2rem",
    },
    discard: {
        color: 'grey',
    },
}));

const getReadOnlyCategoryCard = (subCategory, searchValue) => {
    return <ReadOnlyCategoryCard categoryName={subCategory.name}
                                 discard={subCategory.discard}
                                 searchValue={searchValue}/>;
};

function Categories() {
    useEffect(() => changeTitle('Categories'), []);

    const classes = useStyles();

    const [categoriesState] = useDataApi(PATH_CATEGORIES, {categories: []});
    const [expandedCategoryId, setExpandedCategoryId] = useState(undefined);
    const [editParent, setEditParent] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const categories = useMemo(() => sortAndFilterCategoriesBySearchInCategoryName(categoriesState.data.categories, searchValue), [categoriesState.data.categories, searchValue]);

    const reload = () => {
        setEditParent(false);
    };

    const handleExpansionPanelChange = id => (event, expanded) => {
        setExpandedCategoryId(expanded ? id : undefined);
        setEditParent(false);
    };

    const showHeadingContent = (category) => {
        const editParentButton = (
            <IconButton onClick={event => {
                event.stopPropagation();
                setEditParent(true);
            }}>
                <EditIcon fontSize="small"/>
            </IconButton>
        );

        const button = (expandedCategoryId === category.id && !editParent) ? editParentButton : null;

        const discardClass = category.discard ? classes.discard : '';
        return <Typography variant="h5" className={classes.expansionHeading + ' ' + discardClass}>
            <Highlight input={category.name} highlightValue={searchValue}/>
            {button}
        </Typography>;
    };

    const showCategories = () => {
        if (categories.length === 0) {
            return (
                <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="h5">No Categories</Typography>
                </Grid>
            );
        } else {
            return categories.map(category =>
                <Accordion key={category.id}
                           expanded={expandedCategoryId === category.id}
                           onChange={handleExpansionPanelChange(category.id)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        {showHeadingContent(category)}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction="row" spacing={6}>
                            {showEditParent(category)}
                            {showSubCategories(category)}
                            <Grid item>
                                <AddComponentToggle componentToAdd={
                                    <EditableCategoryCard
                                        parentCategories={categories}
                                        categoryId={0}
                                        categoryName={""}
                                        discard={false}
                                        parentId={category.id}
                                        hasSubCategories={false}
                                        onChangeAction={reload}/>
                                }/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            );
        }
    };

    const showEditParent = (category) => {
        if (expandedCategoryId !== category.id || !editParent) {
            return null;
        }
        return (
            <Grid item xs={12}>
                {getEditableCategoryCard(category, () => setEditParent(false))}
            </Grid>
        );
    };

    const showSubCategories = (category) => {
        if (!category.children.length) {
            return (
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>No sub-categories</Typography>
                </Grid>
            );
        }
        return category.children.sort((a, b) => a.name.localeCompare(b.name)).map(subCategory =>
            <Grid key={subCategory.id} item xs={6} sm={4}>
                <EditToggle
                    editComponent={getEditableCategoryCard(subCategory)}
                    readOnlyComponent={getReadOnlyCategoryCard(subCategory, searchValue)}
                />
            </Grid>
        );
    };

    const getEditableCategoryCard = (category, cancelButtonAction = undefined) => {
        return <EditableCategoryCard
            parentCategories={categories}
            categoryId={category.id}
            categoryName={category.name}
            discard={category.discard}
            parentId={category.parentId ? category.parentId : 0}
            hasSubCategories={!!category.children && category.children.length !== 0}
            onChangeAction={reload}
            cancelButtonAction={cancelButtonAction}
        />;
    };

    const handleSearchChanged = (value) => {
        setSearchValue(value.toLowerCase());
    };

    const handleSearchCleared = () => {
        setSearchValue("");
    };

    if (categoriesState.error) {
        return <Typography variant="subtitle1">{categoriesState.error.message}</Typography>;
    }

    if (categoriesState.isLoading) {
        return <Typography variant="subtitle1">Loading ...</Typography>;
    }

    return <>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <Typography variant="h4" gutterBottom>Categories</Typography>
            </Grid>
            <Grid item>
                <SearchBox
                    value={searchValue}
                    onKeyUp={handleSearchChanged}
                    onClear={handleSearchCleared}/>
            </Grid>
        </Grid>
        {showCategories()}
        <AddComponentToggle
            componentToAdd={
                <EditableCategoryCard
                    parentCategories={categories}
                    categoryId={0}
                    categoryName={""}
                    discard={false}
                    parentId={0}
                    hasSubCategories={false}
                    onChangeAction={reload}/>}
        />
    </>;
}

export default Categories;
