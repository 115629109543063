export const PATH_ACCOUNTS = '/accounts';
export const PATH_ACCOUNTS_MINE = PATH_ACCOUNTS + '/mine';
export const PATH_PARSERS = PATH_ACCOUNTS + '/parsers';
export const PATH_PARSER_INFO = PATH_ACCOUNTS + '/parserInfo';
export const PATH_USERS = '/users';
export const PATH_USERS_ME = PATH_USERS + '/me';
export const PATH_CATEGORIES = '/categories';
export const PATH_REPORT_MINE = 'report/category/mine';
export const PATH_RULES = '/rules';
export const PATH_UPLOADS = '/uploads';
export const PATH_TRANSACTIONS = '/transactions';
export const PATH_TRANSACTIONS_MINE = '/transactions/mine';
export const PATH_TRANSACTIONS_MULTI = '/transactions/multiple/expenses';
export const PATH_JOBS = '/jobs';
export const PATH_VERSION = '/monitoring/version';