import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Autosuggest from "react-autosuggest";
import match from 'autosuggest-highlight/match';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import {makeStyles} from '@material-ui/core/styles';
import Popper from "@material-ui/core/Popper";
import Highlight from "../UtilComponents/Highlight";

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        height: '100%',
    },
    textFieldRoot: {
        height: '100%',
    },
    inputRoot: {
        height: '100%',
    },
    inputRootDense: {
        marginTop: `${theme.spacing(0.5)}px !important`,
    },
    inputLabelRootDense: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: '0.9rem',
        transform: `translate(0, ${theme.spacing(1)}px) scale(1) !important`,
    },
    input: {
        minWidth: '12rem',
        paddingBottom: theme.spacing(0.5),
    },
    inputMarginDense: {
        fontSize: '0.8rem',
    },
    inputLabelShrink: {
        transform: 'translate(0, 1.5px) scale(0.5) !important',
    },
    suggestionsContainerOpen: {
        overflowY: 'scroll',
        maxHeight: theme.spacing(30),
    },
    suggestionsContainerOpenDense: {
        overflowY: 'scroll',
        maxHeight: theme.spacing(20),
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: {
        minHeight: theme.spacing(5),
    },
    suggestionDense: {
        display: 'block',
        fontSize: '0.8rem',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        minHeight: 0,
    },
    suggestionHighlight: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    popper: {
        zIndex: theme.zIndex.modal + 200,
    },
}));

function CategoryPicker(props) {
    const classes = useStyles();
    const availableCategoriesValues = Object.values(props.availableCategories);
    const [anchorEl, setAnchorEl] = useState(null);
    const [stateSelectedCategory, setSelectedCategory] = useState(getSelectedCategory());
    const [stateSuggestions, setSuggestions] = useState([]);

    const handleSuggestionsFetchRequested = ({value}) => {
        setSuggestions(getSuggestions(value));
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    function getSelectedCategory() {
        return props.categoryId ? availableCategoriesValues.find(c => {
            return c.id === props.categoryId;
        }).name : "";
    }

    function getSuggestions(query) {
        return query.trim().length === 0
            ? availableCategoriesValues
            : availableCategoriesValues.filter(suggestion => match(suggestion.name, query).length > 0);
    }

    function renderInputComponent(inputProps) {
        const {
            inputRef = () => {
            }, ref, ...other
        } = inputProps;
        return (
            <TextField
                label='Category'
                fullWidth
                margin={props.dense ? 'dense' : 'none'}
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    inputProps: {
                        autoComplete: 'off', autoCorrect: 'off', autoCapitalize: 'off', spellCheck: 'false',
                    },
                    classes: {
                        root: classes.inputRoot,
                        formControl: classes.formControlRoot,
                        marginDense: classes.inputRootDense,
                        input: classes.input,
                        inputMarginDense: classes.inputMarginDense,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabelRoot,
                        marginDense: classes.inputLabelRootDense,
                        shrink: props.dense ? classes.inputLabelShrink : '',
                    },
                }}
                classes={{
                    root: classes.textFieldRoot,
                }}
                {...other}
            />
        );
    }

    function renderSuggestionsContainer({containerProps, children}) {
        return (
            <Popper anchorEl={anchorEl} open={Boolean(children)} className={classes.popper} placement="bottom-start">
                <Paper elevation={2} square {...containerProps}>
                    {children}
                </Paper>
            </Popper>
        );
    }

    function renderSuggestion(suggestion, {query, isHighlighted}) {
        return (
            <MenuItem selected={isHighlighted} component="div" dense={props.dense}
                      classes={{root: props.dense ? classes.suggestionDense : classes.suggestion}}>
                <Highlight input={suggestion.name}
                           highlightValue={query}
                           highlightStyle={classes.suggestionHighlight}
                           removeNonBreakingSpace/>
            </MenuItem>
        );
    }

    function handleSuggestionSelected(event, {suggestion}) {
        if (props.onSave) {
            props.onSave(suggestion.id);
        }
    }

    function handleChange(event, {newValue}) {
        setSelectedCategory(newValue);
    }

    const autosuggestProps = {
        suggestions: stateSuggestions,
        onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: handleSuggestionsClearRequested,
        getSuggestionValue: s => s.name,
        renderInputComponent: renderInputComponent,
        renderSuggestionsContainer: renderSuggestionsContainer,
        renderSuggestion: renderSuggestion,
        onSuggestionSelected: handleSuggestionSelected,
        shouldRenderSuggestions: () => true,
        focusInputOnSuggestionClick: false,
    };

    return (
        <Autosuggest
            {...autosuggestProps}
            id={props.categoryPickerId}
            inputProps={{
                value: stateSelectedCategory,
                onChange: handleChange,
                autoFocus: !!props.autoFocus,
                onBlur: props.onBlur,
                inputRef: node => {
                    setAnchorEl(node);
                },
            }}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: props.dense ? classes.suggestionsContainerOpenDense : classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
            }}
        />
    );
}

CategoryPicker.propTypes = {
    categoryPickerId: PropTypes.string.isRequired,
    categoryId: PropTypes.number,
    availableCategories: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    onBlur: PropTypes.func,
    dense: PropTypes.bool,
};

export default CategoryPicker;
