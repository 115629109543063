import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EditExpenseForm from "./EditExpenseForm";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddIcon from '@material-ui/icons/Add';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button/Button";
import Optional from "../UtilComponents/Optional";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as PropTypes from "prop-types";
import {formatCcy} from "../utils/utils";
import {ServerContext} from "../Auth/AppAuth";

const useTotalStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
    },
    warning: {
        color: theme.palette.error.main,
    },
    remaining: {
        fontSize: theme.typography.subtitle2.fontSize,
    },
}));

function Total(props) {
    const classes = useTotalStyles();

    const correct = props.expensesTotal === Math.abs(props.transactionAmount);
    const formattedTotal = formatCcy(props.expensesTotal / 100);
    const formattedExpected = formatCcy(Math.abs(props.transactionAmount) / 100);
    const formattedRemaining = formatCcy((Math.abs(props.transactionAmount) - props.expensesTotal) / 100);

    return (
        <div className={classes.root}>
            <Typography variant="subtitle2" component="span" className={correct ? '' : classes.warning}>
                Total: {formattedTotal} of {formattedExpected}
            </Typography>
            {!correct && <Typography className={classes.remaining} variant="subtitle1" component="span">
                &nbsp;({formattedRemaining} remaining)
            </Typography>
            }
        </div>
    );
}

Total.propTypes = {
    expensesTotal: PropTypes.number.isRequired,
    transactionAmount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    dialogContent: {
        minHeight: theme.spacing(36),
        minWidth: theme.breakpoints.only("sm"),
    },
    transactionDescription: {
        marginLeft: theme.spacing(2),
    },
    splitIcon: {
        transform: "scaleY(-1)",
    },
}));

function EditExpensesDialog({
                                categories,
                                expenses,
                                isDialogOpen,
                                onClose,
                                onSave,
                                transactionAmount,
                                transactionDescription,
                                users
                            }) {
    const classes = useStyles();
    const serverContext = useContext(ServerContext);

    const [tempExpenses, setTempExpenses] = useState(expenses);
    const [isSaving, setIsSaving] = useState(false);
    const expensesTotal = tempExpenses.reduce((total, e) => total + e.amount, 0);
    const [cacheBuster, setCacheBuster] = useState(() => new Date().getTime());

    useEffect(() => setTempExpenses(expenses),[expenses]);

    const addExpense = () => {
        setTempExpenses(prevExpenses => prevExpenses.concat([{"amount": 0, "categoryId": 0, "randomId": Math.random()}]));
    };

    const addExpenseWithHalfAmount = () => {
        const total = tempExpenses[0].amount;
        const half = Math.floor(total / 2);
        setTempExpenses(prevExpenses => {
            prevExpenses[0].amount = total - half;
            prevExpenses = prevExpenses.concat([{"amount": half, "categoryId": 0, "randomId": Math.random()}]);
            return prevExpenses;
        });
        setCacheBuster(new Date().getTime());
    };

    const deleteExpense = index => {
        setTempExpenses(prevExpenses => prevExpenses.slice(0, index).concat(prevExpenses.slice(index + 1)));
    };

    const editExpense = (index, expense) => {
        setTempExpenses(prevExpenses => {
            const expensesCopy = [...prevExpenses];
            expensesCopy[index] = {...expensesCopy[index], ...expense};
            return expensesCopy;
        });
    };

    const handleSave = () => {
        setIsSaving(true);
        onSave(tempExpenses);
    };

    const editMode = expenses.some(e => !!e.id);
    const hasBlankExpense = tempExpenses.some(e => !(e.amount && e.categoryId && e.userId));
    const hasMultipleExpenses = tempExpenses.length > 1;

    return (
        <Dialog maxWidth="md"
                open={isDialogOpen}
                onClose={() => onClose()}>

            <DialogTitle id="form-dialog-title">{editMode ? 'Edit' : 'Add'} Expenses
                <Typography className={classes.transactionDescription} component="span"
                            variant="body2">{transactionDescription}</Typography>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                {tempExpenses.map((e, index) =>
                    <EditExpenseForm key={e.randomId || e.id}
                                     formKey={String(e.randomId || e.id)}
                                     amount={e.amount}
                                     userId={e.userId}
                                     categoryId={e.categoryId}
                                     currentUserId={parseInt(serverContext.user.sub)}
                                     availableUsers={users}
                                     availableCategories={categories}
                                     onDelete={() => deleteExpense(index)}
                                     onChange={expense => editExpense(index, expense)}
                                     cacheBuster={cacheBuster}
                    />,
                )}

                <Optional hidden={hasBlankExpense}>
                    <IconButton onClick={addExpense}>
                        <AddIcon/>
                    </IconButton>
                </Optional>
                <Optional hidden={hasMultipleExpenses}>
                    <IconButton onClick={addExpenseWithHalfAmount} className={classes.splitIcon}>
                        <CallSplitIcon/>
                    </IconButton>
                </Optional>

            </DialogContent>

            <DialogActions>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Total expensesTotal={expensesTotal} transactionAmount={transactionAmount}/>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => onClose()} color="secondary">
                            Cancel
                        </Button>
                        <Button disabled={hasBlankExpense || isSaving} onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

EditExpensesDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    expenses: PropTypes.array.isRequired,
    transactionId: PropTypes.number.isRequired,
    transactionAmount: PropTypes.number.isRequired,
    transactionDescription: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    categories: PropTypes.object.isRequired,
};

export default EditExpensesDialog;
