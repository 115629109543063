import {makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import Typography from "@material-ui/core/Typography/Typography";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Grid from "@material-ui/core/Grid/Grid";
import CategoryPicker from "./CategoryPicker";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import * as PropTypes from "prop-types";
import {multiSaveTransactionExpenses, createDefaultExpenses} from "./ExpensesUtils";

const useStyles = makeStyles(theme => ({
    warning: {
        color: theme.palette.error.main,
        margin: theme.spacing(0.5),
    },
    subtitle: {
        marginLeft: theme.spacing(2),
    },
}));

function MultiTransactionSetCategoryDialog(props) {

    const classes = useStyles();

    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [categoryId, setCategoryId] = useState(0);

    const isInvalid = !categoryId;

    const handleSave = () => {
        setIsSaving(true);

        let transactionExpenses = new Map();
        props.selectedTransactions.forEach((txn) => {
            transactionExpenses.set(txn.id, createDefaultExpenses(txn.amount, props.accounts[txn.accountId].users, txn.id, categoryId));
        });

        multiSaveTransactionExpenses(transactionExpenses)
            .then((result) => {
                setIsSaving(false);
                props.onSaveSuccess(result.data.transactionExpenses);
            })
            .catch(error => setError(error.message));
    };


    if (error) {
        return <Typography className={classes.warning}>{error}</Typography>;
    }

    return (
        <Dialog maxWidth="sm"
                open={props.isDialogOpen}
                onClose={props.onClose}>
            <DialogTitle>Set Category for multiple Transactions
                <Typography className={classes.subtitle} component="span" variant="body2">
                    {props.selectedTransactions.length === 1 ? `(${props.selectedTransactions.length} transaction selected)` : `(${props.selectedTransactions.length} transactions selected)`}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item xs={12}>
                        <CategoryPicker categoryPickerId={"multi-set-category-dialog"}
                                        availableCategories={props.categories}
                                        onSave={setCategoryId}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    Cancel
                </Button>
                <Button disabled={isInvalid || isSaving} onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MultiTransactionSetCategoryDialog.propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSaveSuccess: PropTypes.func.isRequired,
    refreshTxnTable: PropTypes.func.isRequired,
    accounts: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    selectedTransactions: PropTypes.array.isRequired,
};

export default MultiTransactionSetCategoryDialog;