import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Typography from "@material-ui/core/Typography";
import {formatCcyDefault} from '../utils/utils';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Highlight from "../UtilComponents/Highlight";

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.background.default,
    },
    cardPadding: {
        padding: theme.spacing(1),
    }
}));

function ReadOnlyRulesCard(props) {
    const classes = useStyles();

    return <Card className={classes.card}>
        <CardHeader className={classes.cardPadding}
                    action={
                        <IconButton onClick={props.editButtonAction ? props.editButtonAction : () => {}}>
                            <EditIcon fontSize="small"/>
                        </IconButton>
                    }
                    title={<Highlight input={props.pattern}
                                      highlightValue={props.searchValue}/>}
                    titleTypographyProps={{variant: "subtitle1"}}
        />
        <CardContent className={classes.cardPadding}>
            <Typography>
                {(props.ruleAmountMin === 0 && props.ruleAmountMax === 0) ?
                    <i>Any amount</i> :
                    <span>{formatCcyDefault(props.ruleAmountMin / 100,
                        <i>Any</i>)} - {formatCcyDefault(props.ruleAmountMax / 100,
                        <i>any</i>)}</span>}
            </Typography>
        </CardContent>
    </Card>;
}

ReadOnlyRulesCard.propTypes = {
    editButtonAction: PropTypes.func,
    ruleAmountMin: PropTypes.number.isRequired,
    ruleAmountMax: PropTypes.number.isRequired,
    pattern: PropTypes.string.isRequired,
    searchValue: PropTypes.string.isRequired,
};

export default ReadOnlyRulesCard;
