import React, {Fragment, useContext, useState} from "react";
import AccountCircle from '@material-ui/icons/AccountCircle';
import {PATH_USERS} from "./config";
import API from "./api";
import Typography from "@material-ui/core/Typography";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button/Button";
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {Hidden} from "@material-ui/core";
import {ServerContext} from "./Auth/AppAuth";
import {useLocalStorage} from "./Hooks";
import ExposureIcon from "@material-ui/icons/Exposure";
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import * as PropTypes from "prop-types";

export const CREDIT_DEBIT = {
    BOTH: 'BOTH',
    CREDITS: 'CREDITS',
    DEBITS: 'DEBITS',
};

export const EXPORT_COLUMN = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
};

function CreditDebitToggleButtons(props) {
    return <ToggleButtonGroup size="small" exclusive={true} value={props.value} onChange={props.onChange}>
        <ToggleButton value={CREDIT_DEBIT.CREDITS}>Credits</ToggleButton>
        <ToggleButton value={CREDIT_DEBIT.DEBITS}>Debits</ToggleButton>
        <ToggleButton value={CREDIT_DEBIT.BOTH}>Both</ToggleButton>
    </ToggleButtonGroup>;
}

CreditDebitToggleButtons.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function LoginInfo() {

    const serverContext = useContext(ServerContext);
    const [nickname, setNickname] = useState(serverContext.user.name);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [error, setError] = useState(null);
    const [creditDebit, setCreditDebit] = useLocalStorage('transactions.credit_debit', CREDIT_DEBIT.BOTH);
    const [exportColumn, setExportColumn] = useLocalStorage('transactions.export_column', EXPORT_COLUMN.LEFT);

    const handleClickUser = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickRename = () => {
        handleCloseMenu();
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCreditDebitChanged = value => {
        setCreditDebit(value);
    };

    const handleExportColumnChanged = value => {
        setExportColumn(value);
    };

    const handleClickLogout = () => {
        handleCloseMenu();
        serverContext.signOut();
    };

    const handleSaveName = () => {
        API.put(`${PATH_USERS}/${serverContext.user.sub}`, {name: nickname})
            .then(() => {
                handleCloseDialog();
                serverContext.refresh();
            })
            .catch(error => setError(error.message));
    };

    return <Fragment>
        <Typography variant="body2" noWrap>
            <Hidden xsDown>{serverContext.user.name || serverContext.user.email}</Hidden>
            <IconButton onClick={handleClickUser}>
                <AccountCircle/>
            </IconButton>
            <Menu anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}>
                <MenuItem onClick={handleClickRename}>
                    <ListItemIcon><PersonIcon/></ListItemIcon>
                    Change Name
                </MenuItem>
                <MenuItem>
                    <ListItemIcon><ExposureIcon/></ListItemIcon>
                    <CreditDebitToggleButtons value={creditDebit} onChange={(e, v) => v ? handleCreditDebitChanged(v) : {}}/>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon><ImportExportIcon/></ListItemIcon>
                    <ToggleButtonGroup size="small" exclusive={true} value={exportColumn} onChange={(e, v) => v ? handleExportColumnChanged(v) : {}}>
                        <ToggleButton value={EXPORT_COLUMN.LEFT}>Left</ToggleButton>
                        <ToggleButton value={EXPORT_COLUMN.RIGHT}>Right</ToggleButton>
                    </ToggleButtonGroup>
                </MenuItem>
                <MenuItem onClick={handleClickLogout}>
                    <ListItemIcon><LogoutIcon/></ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Typography>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Change Name</DialogTitle>
            <DialogContent>
                <DialogContentText>Change the name you wish to be known as.</DialogContentText>
                <TextField autoFocus margin="dense" label="Nickname" fullWidth value={nickname}
                           onChange={e => setNickname(e.target.value)}/>
                {error ? <Typography>{error}</Typography> : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSaveName} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>;

}

export default LoginInfo;
