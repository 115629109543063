export const URL_SEPARATOR = '-';
export const REQUEST_SEPARATOR = ',';

export const getAccountsWithImage = accounts => {
    return accounts.map(acc => ({...acc, pictureUrl: `bank-icons/${acc.parser.toLowerCase()}.png`}));
};

export const getIdsArrayFromURLString = (idString) => {
    return idString.split(URL_SEPARATOR)
        .map(id => parseInt(id))
        .filter(id => id !== undefined);
};

export const formatIdsForURL = (accountIds) => {
    return accountIds.sort().join(URL_SEPARATOR);
};

export const formatIdsForRequest = (accountIds) => {
    return accountIds.join(REQUEST_SEPARATOR);
};