import React, {useEffect, useRef, useState} from "react";
import jwt_decode from "jwt-decode";
import Grid from "@material-ui/core/Grid/Grid";
import {makeStyles, SnackbarContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const GoogleSignInButton = () => {
    const signInButton = useRef(null);

    useEffect(() => {
        window.google.accounts.id.renderButton(signInButton.current, {});
    }, []);

    return <div ref={signInButton}/>;
};

const useStyles = makeStyles((theme) => ({
    errorContent: {
        marginTop: 4
    },
    content: {
        padding: theme.spacing(2),
    },
}));

const SignInWithGoogle = (props) => {
    const classes = useStyles();
    const GOOGLE_SCRIPT_TAG_ID = "google-client-script";

    const [error, setError] = useState(null);

    const onGoogleSignInPromptNotification = (notification) => {
        console.log("onGoogleSignInPromptNotification ", notification);
        if (notification.isDisplayed()) {
            setError(null);
        } else if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            const reason = notification.getNotDisplayedReason() || notification.getSkippedReason();
            console.log("googleSignIn not displayed or skipped", reason);
            setError(`googleSignIn not displayed or skipped: ${reason}`);
        }
    };

    const triggerGoogleSignInPrompt = () => {
        window.google.accounts.id.prompt(onGoogleSignInPromptNotification);
    };

    const onGoogleSignInSuccess = (response) => {
        const decodedUser = jwt_decode(response.credential);
        console.log('Google user now:', decodedUser);
        props.onClientAuthenticated({jwt: response.credential, expiry: decodedUser.exp, email: decodedUser.email});
    };

    const initGoogleSignIn = function () {
        window.google.accounts.id.initialize({
            client_id: window._ika_env.REACT_APP_AUTH_CLIENT_ID,
            callback: onGoogleSignInSuccess,
        });
        triggerGoogleSignInPrompt();
    };

    useEffect(() => {
        if (!document.getElementById(GOOGLE_SCRIPT_TAG_ID)) {
            const script = document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            script.onload = initGoogleSignIn;
            script.async = true;
            script.id = GOOGLE_SCRIPT_TAG_ID;
            document.querySelector("body").appendChild(script);
        } else {
            window.google.accounts.id.disableAutoSelect();
            initGoogleSignIn();
        }

        return () => {
            window.google.accounts.id.cancel();
        }; // eslint-disable-next-line
    }, []);

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center"
              spacing={2} className={classes.errorContent}>
            <Grid item>
                <Typography className={classes.content}>Please sign in using your Google account</Typography>
            </Grid>
            {error && <>
                <Grid item><GoogleSignInButton/></Grid>
                <Grid item><SnackbarContent message={`An error occurred logging in: ${error}`}/></Grid>
            </>}
        </Grid>
    );
};

SignInWithGoogle.propTypes = {
    onClientAuthenticated: PropTypes.func.isRequired,
};

export default SignInWithGoogle;