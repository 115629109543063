import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {Area, AreaChart, CartesianGrid, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {
    changeTitle,
    currentMonthYear,
    formatCcy,
    formatRoundedCcy,
    formatYearMonth,
    jsMonthOf,
    padMonth,
    removeUndefined,
    toMapById,
    validDate
} from "../utils/utils";
import MonthPicker from "../MonthPicker/MonthPicker";
import {useUrlParams} from "../Hooks";
import {useDataApi} from "../api";
import {flattenCategories} from "../utils/categories";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    amber,
    blue,
    blueGrey,
    brown,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from "@material-ui/core/colors";
import {Navigate} from "react-router";
import MultiSelectChips from "../UtilComponents/MultiSelectChips";
import {PATH_ACCOUNTS_MINE, PATH_REPORT_MINE} from "../config";
import {formatIdsForRequest, formatIdsForURL, getIdsArrayFromURLString, getAccountsWithImage} from "../utils/url-search";
import Optional from "../UtilComponents/Optional";
import * as PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip/Chip";

const useStyles = makeStyles(theme => ({
    chart: {
        fontFamily: theme.typography.fontFamily,
    },
    link: {
        cursor: 'pointer',
    },
    txnsFabContainer: {
        position: 'relative',
    },
    txnsBtn: {
        float: 'right'
    },
    pieLabel: {
        fontSize: "1vw"
    },
    transactionLink: {
        textDecoration: "none"
    },
    heading: {
        flexGrow: 1
    },
    dashboardHeader: {
        marginBottom: theme.spacing(0.2)
    },
    select: {
        minWidth: theme.spacing(15),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

Dashboard.COLORS = [
    red["500"],
    pink["500"],
    amber["500"],
    purple["500"],
    blue["500"],
    green["500"],
    orange["500"],
    brown["500"],
    indigo["500"],
    lime["500"],
    lightBlue["500"],
    deepPurple["500"],
    deepOrange["500"],
    lightGreen["500"],
    yellow["600"],
    teal["500"],
    blueGrey["500"],
];

Dashboard.categoryNotDiscarded = (cId, categoriesById) => !(categoriesById[cId] || {}).discard;

Dashboard.filterOutDiscarded = (cats, categoriesById) => cats.filter(c => Dashboard.categoryNotDiscarded(c.categoryId, categoriesById));

Dashboard.defaultParams = () => {
    const monthYear = currentMonthYear();
    const toYear = monthYear.year;
    const toMonth = padMonth(monthYear.month + 1);
    const fromYear = toYear - 1;
    const fromMonth = padMonth(monthYear.month + 1);
    return {fromYear, fromMonth, toYear, toMonth};
};

function DashboardAreaChart(props) {
    const classes = useStyles();
    const {
        selectedCats,
        selectedCategory,
        selectedDetail,
        setSelectedDetail,
        getCategoryName,
        getCategoryColor,
        areaData
    } = props;

    const total = yearMonth => {
        // TODO: Investigate why trying to get total of month not in graph (set to one month view to test)
        try {
            return Object.values(areaData.filter(d => d.yearMonth === yearMonth)[0]).map(v => parseInt(v)).filter(Number.isInteger).reduce((a, b) => a + b, 0);
        } catch (e) {
            console.warn('Could not calculate total:', e.message, yearMonth, areaData);
            return '';
        }
    };

    // eslint-disable-next-line react/prop-types
    const renderCustomizedActiveDot = ({cx, cy, fill, dataKey, payload}) => {
        Object.prototype.hasOwnProperty.call(payload, dataKey) &&
        <circle cx={cx} cy={cy} r={4} stroke="white" strokeWidth={2} fill={fill}
                onClick={d => selectedCategory.current = d.dataKey}/>;
    };

    return <>
        <ResponsiveContainer width={props.width} minHeight={props.minHeight} minWidth={props.minWidth}>
            <AreaChart data={areaData} className={classes.chart}
                       onClick={d => setSelectedDetail({
                           yearMonth: d.activeLabel,
                           categoryId: parseInt(selectedCategory.current)
                       })}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="yearMonth" tickFormatter={ym => formatYearMonth(ym)} label={<Typography/>}/>
                <YAxis tickFormatter={value => formatRoundedCcy(value / 100)}/>
                <Tooltip
                    labelFormatter={l => <Typography component='span'
                                                     variant='h6'>{formatYearMonth(l)}: {formatCcy(total(l) / 100)}</Typography>}
                    formatter={value => formatCcy(value / 100)}
                    itemSorter={item => -item.dataKey}
                />
                {
                    Array.from(selectedCats).sort((a, b) => a - b).map((cId) =>
                        <Area key={cId} type="monotone" dataKey={cId} connectNulls={true}
                              name={getCategoryName(cId)} stackId="1"
                              stroke={getCategoryColor(cId)}
                              fill={getCategoryColor(cId)}
                              fillOpacity={selectedDetail.categoryId === cId ? 1 : 0.65}
                              onClick={d => selectedCategory.current = d.dataKey}
                              activeDot={renderCustomizedActiveDot}
                        />)
                }
            </AreaChart>
        </ResponsiveContainer>
    </>;
}

DashboardAreaChart.propTypes = {
    selectedCats: PropTypes.array.isRequired,
    selectedCategory: PropTypes.number.isRequired,
    selectedDetail: PropTypes.object.isRequired,
    setSelectedDetail: PropTypes.func.isRequired,
    getCategoryName: PropTypes.func.isRequired,
    getCategoryColor: PropTypes.func.isRequired,
    areaData: PropTypes.array.isRequired,
};


function DashboardAreaChartControls(props) {
    const {toggleCategory, selectedCats, setSelectedCategories, availableCats, getCategoryName, getCategoryColor} = props;

    const toGridButton = (cId) =>
        <Grid item key={cId}>
            <Button variant="outlined" size="small"
                    style={{
                        color: getCategoryColor(cId),
                        borderColor: getCategoryColor(cId),
                    }}
                    onClick={() => toggleCategory(cId)}
            >{getCategoryName(cId)} </Button>
        </Grid>;

    return <Grid container justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
            <ButtonGroup size="small" variant='outlined'>
                <Button disabled={availableCats.every(c => selectedCats.has(c))}
                        onClick={() => setSelectedCategories(new Set(availableCats))}>All</Button>
                <Button disabled={selectedCats.size === 0}
                        onClick={() => setSelectedCategories(new Set())}>None</Button>
            </ButtonGroup>
        </Grid>
        {
            availableCats.map(toGridButton)
        }
    </Grid>;
}

DashboardAreaChartControls.propTypes = {
    availableCats: PropTypes.array.isRequired,
    getCategoryName: PropTypes.func.isRequired,
    getCategoryColor: PropTypes.func.isRequired,
    selectedCats: PropTypes.object.isRequired,
    setSelectedCategories: PropTypes.func.isRequired,
    toggleCategory: PropTypes.func.isRequired
};

function Dashboard() {
    useEffect(() => changeTitle('Dashboard'), []);

    const classes = useStyles();
    const COLORS = Dashboard.COLORS;

    const location = useLocation();
    const navigate = useNavigate();
    const [urlParams, setUrlParams] = useUrlParams();

    const [accountsState] = useDataApi(PATH_ACCOUNTS_MINE, {accounts: []});
    const userAccounts = useMemo(() => getAccountsWithImage(accountsState.data.accounts), [accountsState.data.accounts]);

    const invalidUrlParams = !validDate(urlParams.get('fromYear'), urlParams.get('fromMonth')) || !validDate(urlParams.get('toYear'), urlParams.get('toMonth'))
        || new Date(urlParams.get('fromYear'), urlParams.get('fromMonth')) > new Date(urlParams.get('toYear'), urlParams.get('toMonth'));

    const getUrl = () => {
        let params = {
            "from": `${urlParams.get('fromYear')}-${urlParams.get('fromMonth')}`,
            "to": `${urlParams.get('toYear')}-${urlParams.get('toMonth')}`
        };

        if (urlParams.has('accounts')) {
            const accountIds = getIdsArrayFromURLString(urlParams.get('accounts'));
            params.accounts = formatIdsForRequest(accountIds);
        }

        const qs = new URLSearchParams(params);
        return `${PATH_REPORT_MINE}?${qs}`;
    };

    const handleChangeMultiSelect = (accountIds) => {
        setUrlParams({
            ...urlParams,
            accounts: accountIds.length === 0 ? undefined : formatIdsForURL(accountIds)
        });
    };

    const [reportState] = useDataApi(getUrl(), {categoryTotals: {}, categories: []});
    const categoriesById = useMemo(() => toMapById(flattenCategories(reportState.data.categories)), [reportState.data.categories]);
    const dataBaseline = useMemo(() => Object.assign({}, ...Object.values(reportState.data.categoryTotals).flatMap(v => Object.values(v).map(v => v.categoryId).map(s => ({[s]: 0})))), [reportState.data.categoryTotals]);
    const getCategoryName = useCallback(id => Object.prototype.hasOwnProperty.call(categoriesById, id) ? categoriesById[id].name : 'Unknown', [categoriesById]);
    const getCategoryColor = useCallback(id => Object.prototype.hasOwnProperty.call(categoriesById, String(id)) ? COLORS[Object.keys(categoriesById).indexOf(String(id)) % COLORS.length] : grey["900"], [COLORS, categoriesById]);

    const areaData = useMemo(() => Object.entries(reportState.data.categoryTotals)
            .map(([k, v]) => Object.assign(Object.assign({yearMonth: k}, dataBaseline), ...Dashboard.filterOutDiscarded(v, categoriesById).map(v => ({[v.categoryId]: v.total}))))
            .sort((a, b) => a.yearMonth.localeCompare(b.yearMonth)),
        [reportState.data.categoryTotals, dataBaseline, categoriesById]);

    const averageData = useMemo(() => {
        const totals = areaData.reduce((map, x) => {
            Object.entries(x)
                .filter(e => Number.isInteger(parseInt(e[0])))
                .forEach((e) => map[e[0]] = (Object.prototype.hasOwnProperty.call(map, e[0]) ? map[e[0]] : 0) + e[1]);
            return map;
        }, {});
        const total = Object.values(totals).reduce((a, b) => a + b, 0);
        return Object.entries(totals)
            .filter(e => e[1] > 0)
            .map(e => {
                return {
                    categoryId: parseInt(e[0]),
                    categoryName: getCategoryName(parseInt(e[0])),
                    average: Math.round(e[1] / areaData.length),
                    averageFmt: formatCcy(Math.round(e[1] / areaData.length) / 100),
                };
            }).concat([{
                categoryId: NaN,
                categoryName: "Total",
                average: Math.round(total / areaData.length),
                averageFmt: formatCcy(Math.round(total / areaData.length) / 100),
            }]);
    }, [areaData, getCategoryName]);

    const availableCats = useMemo(() => Array.from(new Set(
        areaData.flatMap(m => Object.keys(m)
            .filter(k => m[k] > 0)
            .map(k => parseInt(k))
            .filter(Number.isInteger))
    ).values()).sort((a, b) => a - b), [areaData]);

    const [selectedCats, setSelectedCategories] = useState(new Set(availableCats));

    const selectAllCatsAfterFirstLoad = () => {
        if (selectedCats.size === 0) {
            setSelectedCategories(new Set(availableCats));
        }
    };
    // TODO: React Hook useEffect has a missing dependency: 'selectedCats.size'. Either include it or remove the dependency array
    useEffect(selectAllCatsAfterFirstLoad, [availableCats]);

    // Cannot capture x & y in one event so keep a reference to Area and do real work on AreaChart
    const selectedCategory = useRef();

    const [selectedDetail, setSelectedDetail] = useState({});
    // TODO: De-select on date change

    const pieData = selectedDetail.yearMonth
        ? Dashboard.filterOutDiscarded(reportState.data.categoryTotals[selectedDetail.yearMonth], categoriesById)
        : [];
    let subPieData = [];
    if (selectedDetail.categoryId) {
        const category = pieData.find(c => c.categoryId === selectedDetail.categoryId);
        if (category) {
            subPieData = category.subCategoryTotals;
        }
    }

    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line react/prop-types
    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, categoryId, total, fill,}) => {
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return <>
            <text x={x} y={y} fill={fill} className={classes.pieLabel}
                  textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {getCategoryName(categoryId)}
            </text>
            <br/>
            <text x={x} y={y + 11} fill={fill} className={classes.pieLabel}
                  textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {formatCcy(total / 100)}
            </text>
        </>;
    };

    const toggleCategory = cId => {
        let clone = new Set(selectedCats);
        clone.has(cId) ? clone.delete(cId) : clone.add(cId);
        setSelectedCategories(clone);
    };

    const fromDateSelected = (year, month) => {
        setUrlParams({
            fromYear: year,
            fromMonth: month,
        });
    };

    const toDateSelected = (year, month) => {
        setUrlParams({
            toYear: year,
            toMonth: month,
        });
    };

    if (invalidUrlParams) {
        console.log('[render]', 'Invalid params:', urlParams.toString(), 'Rendering navigate to default');
        const defaultParamsString = new URLSearchParams(Dashboard.defaultParams()).toString();
        return <Navigate to={`${location.pathname}?${defaultParamsString}`} replace/>;
    }

    const renderTxnLink = (selectedDetail, categoryId) => {
        return <Link className={classes.transactionLink}
                     to={{
                         pathname: '/transactions',
                         search: '?' + new URLSearchParams(removeUndefined({
                             month: selectedDetail.yearMonth.split('-')[1],
                             year: selectedDetail.yearMonth.split('-')[0],
                             categories: categoryId
                         }))
                     }}
        >
            <Button className={classes.txnsBtn} size="small" variant="outlined" color="primary">
                See {categoryId ? getCategoryName(categoryId) : formatYearMonth(selectedDetail.yearMonth)} in more
                detail
            </Button>
        </Link>;
    };

    console.log('[render]', 'rendering actual content');
    return (
        <>
            <Grid container direction="row" justifyContent="space-between" className={classes.dashboardHeader}>
                <Grid item>
                    <Typography variant="h4" gutterBottom className={classes.heading}>Dashboard</Typography>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item className={classes.select}>
                            <MultiSelectChips inputTitle="Accounts"
                                              availableItems={userAccounts}
                                              handleChangeMultiSelect={handleChangeMultiSelect}
                                              preSelectedIds={urlParams.has('accounts') ? getIdsArrayFromURLString(urlParams.get('accounts')) : undefined}/>
                        </Grid>
                        <Grid item>
                            <Typography>From:</Typography>
                        </Grid>
                        <Grid item>
                            {/* TODO: from/to bounds are incorrect */}
                            <MonthPicker from={new Date(2012, 7)}
                                         to={new Date(urlParams.get('toYear'), jsMonthOf(urlParams.get('toMonth')))}
                                         year={parseInt(urlParams.get('fromYear'))} month={urlParams.get('fromMonth')}
                                         onChange={fromDateSelected}/>
                        </Grid>
                        <Grid item>
                            <Typography>To:</Typography>
                        </Grid>
                        <Grid item>
                            <MonthPicker
                                from={new Date(urlParams.get('fromYear'), jsMonthOf(urlParams.get('fromMonth')))}
                                to={new Date()}
                                year={parseInt(urlParams.get('toYear'))} month={urlParams.get('toMonth')}
                                onChange={toDateSelected}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Optional hidden={areaData.length !== 0}>
                <Typography align="center" variant="subtitle1">No data found for selected dates.</Typography>
            </Optional>
            <Optional hidden={areaData.length === 0}>
                <Grid container direction="row" spacing={1}>
                    <Grid item sm={12}>
                        <DashboardAreaChart width='100%' minHeight={350} minWidth={350} areaData={areaData}
                                            availableCats={availableCats} selectedCats={selectedCats}
                                            setSelectedCategories={setSelectedCategories}
                                            getCategoryName={getCategoryName}
                                            getCategoryColor={getCategoryColor}
                                            selectedCategory={selectedCategory} selectedDetail={selectedDetail}
                                            setSelectedDetail={setSelectedDetail}/>
                    </Grid>
                    <Grid item sm={12}>
                        <DashboardAreaChartControls
                            availableCats={availableCats} selectedCats={selectedCats}
                            setSelectedCategories={setSelectedCategories}
                            getCategoryName={getCategoryName}
                            getCategoryColor={getCategoryColor}
                            toggleCategory={toggleCategory}
                        />
                    </Grid>
                    <Optional hidden={pieData.length !== 0}>
                        <Grid item sm={12}>
                            <Grid item sm={12}>
                                <Typography variant="h5">Averages</Typography>
                            </Grid>
                            <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
                                {averageData.map((d) => <Grid item sm={3} key={d.categoryId}>
                                    <Chip variant={Number.isNaN(d.categoryId) ? "default" : "outlined"}
                                          label={`${d.categoryName}: ${d.averageFmt}`}
                                          style={Number.isNaN(d.categoryId) ? {} : {
                                              color: getCategoryColor(d.categoryId),
                                              borderColor: getCategoryColor(d.categoryId),
                                          }}
                                    />
                                </Grid>)}
                            </Grid>
                        </Grid>
                    </Optional>
                    <Grid item sm={6}>
                        <ResponsiveContainer width='100%' maxHeight={350} minWidth={350} aspect={1}>
                            <PieChart className={classes.chart} margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                                <Pie dataKey="total" nameKey="categoryId" animationDuration={1000} data={pieData}
                                     outerRadius="90%"
                                     label={renderCustomizedLabel}
                                     blendStroke={true}
                                     labelLine={true}>
                                    {
                                        pieData.map((entry) => (
                                            <Cell key={entry.categoryId}
                                                  className={classes.link}
                                                  fill={getCategoryColor(entry.categoryId)}
                                                  fillOpacity={selectedDetail.categoryId === entry.categoryId ? 1 : 0.65}
                                                  onClick={() => setSelectedDetail({
                                                      ...selectedDetail,
                                                      categoryId: selectedDetail.categoryId === entry.categoryId ? undefined : entry.categoryId
                                                  })}
                                            />
                                        ))
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        {pieData.length ? renderTxnLink(selectedDetail) : ""}
                    </Grid>
                    <Grid item sm={6}>
                        <ResponsiveContainer width='100%' maxHeight={350} minWidth={350} aspect={1}>
                            <PieChart className={classes.chart} margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                                <Pie dataKey="total" nameKey="categoryId" animationDuration={1000} data={subPieData}
                                     outerRadius="90%" label={renderCustomizedLabel}
                                     blendStroke={true}
                                     labelLine={true}>
                                    {
                                        subPieData.map((entry) => (
                                            <Cell key={entry.categoryId}
                                                  className={classes.link}
                                                  fill={getCategoryColor(entry.categoryId)}
                                                  fillOpacity={0.65}
                                                  onClick={() => navigate('/transactions?' + new URLSearchParams({
                                                      month: selectedDetail.yearMonth.split('-')[1],
                                                      year: selectedDetail.yearMonth.split('-')[0],
                                                      categoryId: entry.categoryId === 0 ? selectedDetail.categoryId : entry.categoryId
                                                  }))}
                                            />
                                        ))
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        {subPieData.length ? renderTxnLink(selectedDetail, selectedDetail.categoryId) : ""}
                    </Grid>
                </Grid>
            </Optional>
        </>
    );
}

export default Dashboard;

DashboardAreaChart.propTypes = {
    areaData: PropTypes.any,
    availableCats: PropTypes.any,
    getCategoryName: PropTypes.any,
    minHeight: PropTypes.any,
    minWidth: PropTypes.any,
    selectedCategory: PropTypes.any,
    selectedCats: PropTypes.any,
    selectedDetail: PropTypes.any,
    setSelectedDetail: PropTypes.any,
    width: PropTypes.any
};