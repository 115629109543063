import React, {useEffect} from 'react';
import {Typography} from "@material-ui/core";
import {changeTitle} from "../utils/utils";

function Labs() {
    useEffect(() => changeTitle('Labs'), []);

    return (<div>
            <Typography>
                Cool stuff coming soon x)
            </Typography>
        </div>
    );
}

export default Labs;
