import React, {useEffect, useMemo, useState} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from "@material-ui/core/Typography";
import {useDataApi} from "../api";
import {PATH_TRANSACTIONS_MINE} from "../config";
import {formatCcy, toMapById} from '../utils/utils';
import {CREDIT_DEBIT, EXPORT_COLUMN} from "../LoginInfo";
import PropTypes from "prop-types";
import {makeStyles} from '@material-ui/core/styles';
import TableFooter from "@material-ui/core/TableFooter";
import {formatIdsForRequest} from "../utils/url-search";
import TransactionRow from "./TransactionRow";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import MultiTransactionSetCategoryDialog from "./MultiTransactionSetCategoryDialog";
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Box} from "@mui/material";
import {format as formatDate} from "date-fns";
import {useLocalStorage} from "../Hooks";

const useStyles = makeStyles(theme => ({
    tableRoot: {
        backgroundColor: theme.palette.background.default,
        '& .MuiTableBody-root .MuiTableCell-sizeSmall': {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
    },
    padding: {
        padding: theme.spacing(2),
    },
    error: {
        padding: theme.spacing(2),
        color: theme.palette.error.main,
    },
    checkbox: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(1),
    },
}));

TransactionsTable.processTransactions = (rawTransactions, mods, creditDebit) => {
    const transactionsDeepCopy = JSON.parse(JSON.stringify(rawTransactions));
    if (mods.size > 0) {
        transactionsDeepCopy.forEach((t) => {
            if (mods.has(String(t.id))) {
                t.expenses = mods.get(String(t.id));
            }
        });
    }

    return transactionsDeepCopy
        .filter(t => TransactionsTable.shouldShowTransaction(t, creditDebit))
        .sort((a, b) => {
            return a.dateTsMs - b.dateTsMs || a.accountId - b.accountId || a.description.localeCompare(b.description);
        });
};

TransactionsTable.shouldShowTransaction = (t, creditDebit) => {
    switch (creditDebit) {
        case CREDIT_DEBIT.BOTH:
            return true;
        case CREDIT_DEBIT.CREDITS:
            return t.amount > 0;
        case CREDIT_DEBIT.DEBITS:
            return t.amount < 0;
        default:
            throw new Error("Invalid credit/debit option: " + creditDebit);
    }
};

TransactionsTable.asTsv = (transactions, leftRight) => {
    const padding = leftRight === EXPORT_COLUMN.LEFT ? [] : [""];
    return transactions.map(t => [formatDate(new Date(t.dateTsMs), "d LLL yyyy"), t.description].concat(t.expenses.length > 1 ? [] : padding).concat(t.expenses.map(e => e.amount / 100)).join("\t")).join("\n");
};

function TransactionsTable({
                               accountIds,
                               cacheBuster,
                               categories,
                               categoryIds,
                               creditDebit,
                               openAddRuleDialog,
                               search,
                               isMultiSelectMode,
                               yearMonth,
                               isMultiSetCategoryDialogOpen,
                               refreshTransactionsTable,
                               onMultiSetCategoryDialogClose,
                               onAnyCheckboxesSelected,
                           }) {
    const classes = useStyles();

    const [transactionsState] = useDataApi(getUrl(), {accounts: [], users: [], transactions: []}, cacheBuster);
    const [selectedTxnSet, setSelectedTxnSet] = useState(new Set());
    const [copyDone, setCopyDone] = useState(false);
    const [isParentCheckboxSelected, setIsParentCheckboxSelected] = useState(false);
    const [transactionMods, setTransactionMods] = useState(new Map());
    const [exportColumn, _] = useLocalStorage('transactions.export_column', EXPORT_COLUMN.LEFT);

    const accounts = useMemo(() => toMapById(transactionsState.data.accounts), [transactionsState.data.accounts]);
    const transactions = useMemo(() => TransactionsTable.processTransactions(transactionsState.data.transactions, transactionMods, creditDebit), [transactionsState.data.transactions, transactionMods, creditDebit]);

    useEffect(() => {
        setSelectedTxnSet(new Set());
        setIsParentCheckboxSelected(false);
    }, [transactions]);
    useEffect(() => onAnyCheckboxesSelected(selectedTxnSet.size > 0), [selectedTxnSet, onAnyCheckboxesSelected]);

    function getUrl() {
        let params = search ? {search: search} : {yearMonth: yearMonth};
        if (categoryIds !== undefined) {
            params.categoryIds = formatIdsForRequest(categoryIds);
        }
        if (accountIds !== undefined) {
            params.accounts = formatIdsForRequest(accountIds);
        }
        const qs = new URLSearchParams(params);
        return `${PATH_TRANSACTIONS_MINE}?${qs}`;
    }

    const handleSingleCheckboxSelected = (txnId, selected) => {
        if (selected) {
            selectedTxnSet.add(txnId);
        } else {
            selectedTxnSet.delete(txnId);
        }
        setSelectedTxnSet(new Set(selectedTxnSet));
    };

    const handleParentCheckboxSelected = (event) => {
        setIsParentCheckboxSelected(event.target.checked);
        if (event.target.checked) {
            transactions.forEach((t) => {
                selectedTxnSet.add(t.id);
            });
            setSelectedTxnSet(new Set(selectedTxnSet));
        } else {
            setSelectedTxnSet(new Set());
        }
    };

    const getSelectedTransactions = () => {
        return transactions.filter((t) => {
            return selectedTxnSet.has(t.id);
        });
    };

    const mergeMods = (newMods) => {
        return new Map([...transactionMods, ...newMods]); // duplicate keys will be overridden by last entry
    };

    const handleMultiSaveSuccess = (transactionExpenses) => {
        onMultiSetCategoryDialogClose();
        setTransactionMods(mergeMods(new Map(Object.entries(transactionExpenses))));
    };

    const handleSingleSaveSuccess = (transactionId, expenses) => {
        setTransactionMods(mergeMods(new Map([[transactionId.toString(), expenses]])));
    };

    const handleCopyTransactions = () => {
        navigator.clipboard.writeText(TransactionsTable.asTsv(transactions, exportColumn));
        setCopyDone(true);
    };

    const handleCopyDoneLeave = () => {
        setCopyDone(false);
    };

    if (transactionsState.isLoading) {
        return <LinearProgress/>;
    }
    if (transactionsState.error) {
        return <Typography className={classes.error} variant="h6">{transactionsState.error}</Typography>;
    }
    if (transactions.length === 0) {
        return <Typography className={classes.padding} variant="subtitle1">No transactions found.</Typography>;
    }

    const txnTotalAmountTxt = formatCcy(transactions.map(t => t.amount).reduce((a, b) => a + b, 0) / 100);
    const txnTotalText = `Found ${transactions.length} transaction${transactions.length === 1 ? '' : 's'} totalling ${txnTotalAmountTxt}`;
    return <>
        <Table size="small" classes={{root: classes.tableRoot}}>
            <colgroup>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '60%'}}/>
                <col style={{width: '10%'}}/>
                <col style={{width: '10%'}}/>
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell><Checkbox size={"small"}
                                         indeterminateIcon={<IndeterminateCheckBoxIcon color={"secondary"}/>}
                                         className={classes.checkbox}
                                         checked={isParentCheckboxSelected}
                                         indeterminate={selectedTxnSet.size > 0 && selectedTxnSet.size < transactions.length}
                                         onChange={handleParentCheckboxSelected}/>Account</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell>Expenses</TableCell>
                </TableRow>
            </TableHead>
            <TableFooter>
                <TableRow>
                    <TableCell colSpan={5}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item><IconButton size="small" onClick={handleCopyTransactions}>{copyDone ?
                                <CheckCircleIcon color="primary" onMouseLeave={handleCopyDoneLeave}/> : <CopyAllIcon/>}</IconButton></Grid>
                            <Grid item><Box fontStyle="oblique">{txnTotalText}</Box></Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            </TableFooter>
            <TableBody>
                {transactions.map(txn => <TransactionRow key={txn.id} txn={txn}
                                                         accounts={accounts}
                                                         categories={categories}
                                                         openAddRuleDialog={openAddRuleDialog}
                                                         handleTxnSelected={(event) => handleSingleCheckboxSelected(txn.id, event.target.checked)}
                                                         txnSelected={selectedTxnSet.has(txn.id)}
                                                         multiSelectMode={isMultiSelectMode}
                                                         handleSingleSaveSuccess={handleSingleSaveSuccess}
                />)}
            </TableBody>
        </Table>
        <MultiTransactionSetCategoryDialog isDialogOpen={isMultiSetCategoryDialogOpen}
                                           onClose={onMultiSetCategoryDialogClose}
                                           onSaveSuccess={handleMultiSaveSuccess}
                                           refreshTxnTable={refreshTransactionsTable}
                                           categories={categories}
                                           accounts={accounts}
                                           selectedTransactions={getSelectedTransactions()}
        />
    </>;
}

TransactionsTable.propTypes = {
    yearMonth: PropTypes.string,
    search: PropTypes.string,
    accountIds: PropTypes.arrayOf(PropTypes.number),
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    creditDebit: PropTypes.oneOf(["BOTH", "CREDITS", "DEBITS"] /* should be Object.values(CREDIT_DEBIT) but doesn't work */),
    openAddRuleDialog: PropTypes.func.isRequired,
    categories: PropTypes.object.isRequired,
    cacheBuster: PropTypes.number.isRequired,
    isMultiSelectMode: PropTypes.bool,
    onAnyCheckboxesSelected: PropTypes.func.isRequired,
    isMultiSetCategoryDialogOpen: PropTypes.bool,
    onMultiSetCategoryDialogClose: PropTypes.func.isRequired,
    refreshTransactionsTable: PropTypes.func.isRequired,
};

export default TransactionsTable;
