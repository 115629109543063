import Grid from "@material-ui/core/Grid";
import React, {useCallback, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {FormGroup, makeStyles, SnackbarContent} from "@material-ui/core";
import API from "../api";
import {PATH_USERS_ME} from "../config";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import {useLocalStorage} from "../Hooks";
import SignInWithGoogle from "./SignInWithGoogle";
import FormControl from "@material-ui/core/FormControl";
import Optional from "../UtilComponents/Optional";
import {currentEpochSecs} from "../utils/utils";

const useStyles = makeStyles((theme) => ({
    logoutButton: {
        marginTop: theme.spacing(2),
    },
    errorContent: {
        marginTop: 4
    },
    content: {
        padding: theme.spacing(2),
    },
    monospace: {
        fontFamily: "monospace",
        overflowWrap: "break-word",
    }
}));

const needsRefresh = (expiry) => expiry < currentEpochSecs() + 60;
const justGranted = (issuedAt) => issuedAt < currentEpochSecs() + 60;

const isExpired = (token) => {
    try {
        const payload = jwt_decode(token);
        return needsRefresh(payload.exp);
    } catch (e) {
        console.error('Could not decode JWT', token, e.message);
    }
    return true;
};

export const ServerContext = React.createContext({user: null, refresh: null});

const AppAuth = (props) => {
    const classes = useStyles();

    const [cachedJwt, setCachedJwt] = useLocalStorage('auth.jwt', null);
    const [isAuthenticating, setAuthenticating] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const [error, setError] = useState(null);
    const [clientAuthData, setClientAuthData] = useState({jwt: null});

    const signIn = useCallback((serverBearer) => {
        API.defaults.headers.common['Authorization'] = `Bearer ${serverBearer}`;
        const claims = jwt_decode(serverBearer);
        setAuthenticatedUser(claims);
        setError(null);
        setCachedJwt(serverBearer);
    }, [setCachedJwt]);

    const signOut = useCallback(() => {
        delete API.defaults.headers.common['Authorization'];
        setAuthenticatedUser(null);
        setCachedJwt(null);
        setClientAuthData({jwt: null});
        setError(null);
    }, [setCachedJwt]);

    const authenticate = useCallback((jwt) => {
        setAuthenticating(true);
        API.get(PATH_USERS_ME, {headers: {Authorization: `Bearer ${jwt}`}})
            .then(result => {
                signIn(result.data);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setAuthenticating(false);
            });
    }, [signIn]);

    const refresh = () => {
        authenticate(cachedJwt);
    };

    const onClientAuthenticated = (authData) => {
        setClientAuthData(authData);
    };

    useEffect(() => {
        clientAuthData.jwt && authenticate(clientAuthData.jwt);
    }, [authenticate, clientAuthData.jwt]);

    useEffect(() => {
        if (cachedJwt && !isExpired(cachedJwt) && (!authenticatedUser || !justGranted(authenticatedUser.iat))) {
            authenticate(cachedJwt);
        }
    }, [authenticate, cachedJwt, authenticatedUser]);

    useEffect(() => {
        if (cachedJwt && isExpired(cachedJwt)) {
            signOut();
        }
    }, [authenticate, isExpired, signOut]);

    const isAuthenticated = Boolean(authenticatedUser);
    const needClientAuth = !cachedJwt;

    if (isAuthenticated && needsRefresh(authenticatedUser.exp)) {
        signOut();
    }

    console.debug('AppAuth:', 'isAuthenticated:', isAuthenticated, 'needClientAuth:', needClientAuth, 'isAuthenticating', isAuthenticating,
        'authenticatedUser:', authenticatedUser, 'clientAuthData:', clientAuthData, 'cachedJwt:', cachedJwt && ('...' + cachedJwt.substring(cachedJwt.length - 16)),
        'error', error);

    if (error) {
        const token = cachedJwt || clientAuthData.jwt;
        let jwtDebug = null;
        if (token) {
            try {
                jwtDebug = JSON.stringify({
                    header: jwt_decode(token, {header: true}),
                    payload: jwt_decode(token),
                }, null, 4);
            } catch (e) {
                jwtDebug = e.message;
            }
        }
        return (
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" alignContent="center"
                  spacing={2} className={classes.errorContent}>
                <Grid item xs={7}>
                    <SnackbarContent message={"An error occurred logging in: " + error}/>
                </Grid>
                <Grid item xs={7}>
                    <Typography>
                        The server could not authenticate you.
                    </Typography>
                    {clientAuthData && clientAuthData.email && <Typography>
                        Perhaps you are signed in with the wrong account ({clientAuthData.email})?
                    </Typography>}
                    <Typography>
                        Please sign out and switch user{token && ' or try again'}.
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <FormControl>
                        <FormGroup>
                            <Button variant="contained" className={classes.logoutButton}
                                    onClick={signOut}>Sign out</Button>
                            <Optional hidden={!token}>
                                <Button variant="contained" className={classes.logoutButton}
                                        onClick={() => authenticate(token)}>Try again</Button>
                            </Optional>
                        </FormGroup>
                    </FormControl>
                </Grid>
                {jwtDebug && <>
                    <Grid item xs={7}>
                        <Typography variant="h5">Debug</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h6">JWT</Typography>
                        <div className={classes.monospace}>{token}</div>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h6">Decoded</Typography>
                        <pre>{jwtDebug}</pre>
                    </Grid>
                </>}
            </Grid>
        );
    }

    if (isAuthenticated) {
        return (
            <ServerContext.Provider value={{
                user: authenticatedUser,
                refresh: refresh,
                signOut: signOut,
            }}>
                {props.children}
            </ServerContext.Provider>
        );
    }

    if (isAuthenticating) {
        return (
            <Typography className={classes.content}>Signing in...</Typography>
        );
    }

    if (needClientAuth) {
        return (
            <SignInWithGoogle onClientAuthenticated={onClientAuthenticated}/>
        );
    }

    return (
        <Typography className={classes.content}>Loading...</Typography>
    );
};

AppAuth.propTypes = {
    children: PropTypes.element.isRequired
};

export default AppAuth;