import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {PATH_UPLOADS} from "../config";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Card from '@material-ui/core/Card';
import {format as formatDate} from 'date-fns';
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@material-ui/core";
import {useDataApi} from "../api";

const useStyles = makeStyles(() => ({
    noWrap: {
        whiteSpace: "noWrap",
    }
}));

function Uploads(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [state] = useDataApi(PATH_UPLOADS, {uploads: []}, props.lastChanged);

    if (state.isLoading) {
        return <Typography>Loading ...</Typography>;
    }

    if (state.error) {
        return <Typography>Could not get previous uploads. {state.error}</Typography>;
    }

    return (
        <Grid container justifyContent="center">
            <Grid item sm={12}>
                <Typography variant="h5" gutterBottom>Previous Uploads</Typography>
            </Grid>
            <Grid item lg={8} xs={12}>
                {state.data.uploads.length ?
                    <Card>
                        <Table style={{backgroundColor: theme.palette.background.default}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.noWrap}>File Name</TableCell>
                                    <TableCell className={classes.noWrap}>Content Type</TableCell>
                                    <TableCell className={classes.noWrap} align="right">File Size (kb)</TableCell>
                                    <TableCell className={classes.noWrap} align="right">Uploaded</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.data.uploads.map(row => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell className={classes.noWrap}>{row.filename}</TableCell>
                                            <TableCell>{row.contentType}</TableCell>
                                            <TableCell
                                                align="right">{Number(row.contentSizeBytes / Math.pow(1024, 1)).toFixed(2)}</TableCell>
                                            <TableCell align="right"
                                                       className={classes.noWrap}>{formatDate(new Date(row.dateTsMs), "E do LLL ''yy")}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Card> :
                    <Typography variant="subtitle1">No files have been uploaded.</Typography>
                }
            </Grid>
        </Grid>
    );
}

Uploads.propTypes = {
    lastChanged: PropTypes.number.isRequired,
};

export default Uploads;
