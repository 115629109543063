import React from "react";
import {BrowserRouter, Route} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import ErrorBoundary from "./UtilComponents/ErrorBoundary";
import {Navigate, Routes} from "react-router";
import Dashboard from "./Dashboard/Dashboard";
import Accounts from "./Accounts/Accounts";
import Categories from "./Categories/Categories";
import Rules from "./Rules/Rules";
import Transactions from "./Transactions/Transactions";
import Import from "./Import/Import";
import Labs from "./Labs/Labs";
import {createTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {blue, pink} from "@material-ui/core/colors";
import AppToolBar from "./UtilComponents/AppToolBar";
import AppAuth from "./Auth/AppAuth";

const theme = createTheme({
    palette: {
        primary: blue,
        secondary: pink,
    },
});

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
}));

const App = () => {
    const classes = useStyles();
    const isAuthEnabled = process.env.REACT_APP_AUTH_ENABLED !== 'false';
    const AuthContainer = isAuthEnabled ? AppAuth : React.Fragment;

    return <ThemeProvider theme={theme}>
        <AuthContainer>
            <BrowserRouter>
                <AppBar position="static">
                    <AppToolBar/>
                </AppBar>
                <div className={classes.content}>
                    <ErrorBoundary>
                        <Routes>
                            <Route path="/" element={<Navigate to="/dashboard" replace/>}/>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/accounts" element={<Accounts/>}/>
                            <Route path="/categories" element={<Categories/>}/>
                            <Route path="/rules" element={<Rules/>}/>
                            <Route path="/transactions" element={<Transactions/>}/>
                            <Route path="/import" element={<Import/>}/>
                            <Route path="/labs" element={<Labs/>}/>
                        </Routes>
                    </ErrorBoundary>
                </div>
            </BrowserRouter>
        </AuthContainer>
    </ThemeProvider>;
};

export default App;