import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Jobs from "../Jobs/Jobs";
import LoginInfo from "../LoginInfo";
import Tooltip from "@material-ui/core/Tooltip";
import React, {forwardRef, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask} from "@fortawesome/free-solid-svg-icons";
import API from "../api";
import {PATH_VERSION} from "../config";
import Typography from "@material-ui/core/Typography/Typography";
import {ListItem, ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";

const useStyles = makeStyles(theme => ({
    logo: {
        marginRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        height: `calc(${theme.mixins.toolbar.minHeight}px - ${theme.spacing(2)}px)`,
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
            height: `calc(${theme.mixins.toolbar[theme.breakpoints.up('xs') + ' and (orientation: landscape)'].minHeight}px - ${theme.spacing(2)}px)`,
        },
        [theme.breakpoints.up('sm')]: {
            height: `calc(${theme.mixins.toolbar[theme.breakpoints.up('sm')].minHeight}px - ${theme.spacing(2)}px)`,
        },
    },
    logoImg: {
        height: '100%',
    },
    rightItem: {
        flexGrow: 1,
    },
    hidden: {
        display: 'none',
    },
    root: {
        minWidth: 'inherit',
    },
}));

const LinkTab = tabProps => {
    const classes = useStyles();
    let LinkComponent = (linkProps, ref) => <Link to={tabProps.value} {...linkProps} ref={ref}/>;
    return <Tab classes={{root: classes.root}} component={forwardRef(LinkComponent)} {...tabProps}/>;
};

const NavigationTabs = () => {
    const classes = useStyles();
    const location = useLocation();

    return <Tabs value={location.pathname}
                 indicatorColor="secondary"
                 textColor="secondary"
                 variant="scrollable"
                 scrollButtons="auto">
        <Tab classes={{root: classes.hidden}} value="/"/>
        <LinkTab value="/dashboard" label="Dashboard"/>
        <LinkTab value="/accounts" label="Accounts"/>
        <LinkTab value="/categories" label="Categories"/>
        <LinkTab value="/rules" label="Rules"/>
        <LinkTab value="/transactions" label="Transactions"/>
        <LinkTab value="/import" label="Import"/>
        <LinkTab value="/labs" label={<Grid container spacing={1} justifyContent="center" align-items="center" wrap="nowrap">
            <Grid item>Labs</Grid>
            <Grid item><FontAwesomeIcon icon={faFlask}/></Grid>
        </Grid>}/>
    </Tabs>;
};

const Logo = () => {
    const classes = useStyles();

    const [serverVersion, setServerVersion] = useState('Loading...');

    useEffect(() => {
        API.get(PATH_VERSION)
            .then(result => setServerVersion(result.data))
            .catch(error => setServerVersion(error.message));
    }, []);

    return <Tooltip
        title={<>
            <Typography variant="h6">Version Information</Typography>
            <List dense>
                <ListItem disablePadding>
                    <ListItemText primary={`UI: ${process.env.REACT_APP_VERSION}`}/>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemText primary={`Server: ${serverVersion}`}/>
                </ListItem>
            </List>
        </>}
        placement="bottom">
        <div className={classes.logo}><img src={'ika_logo.png'} alt="IKA" className={classes.logoImg}/></div>
    </Tooltip>;
};

const AppToolBar = () => {
    const classes = useStyles();

    return <Toolbar>
        <Grid item><Logo/></Grid>
        <NavigationTabs/>
        <Grid item className={classes.rightItem}>
            <Grid container justifyContent="flex-end" alignItems="center" wrap="nowrap">
                <Grid item><Jobs/></Grid>
                <Grid item><LoginInfo/></Grid>
            </Grid>
        </Grid>
    </Toolbar>;
};

export default AppToolBar;