import React, {Component} from 'react';
import API from '../api';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid/Grid";
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import {PATH_RULES} from "../config";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {saveRule} from "./RulesApi";
import ControlButtons from "../UtilComponents/ControlButtons";

const styles = theme => ({
    card: {
        backgroundColor: theme.palette.background.default,
    },
    cardContent: {
        padding: theme.spacing(1),
    },
    controlButtons: {
        marginLeft: 4,
        fontSize: 10,
        padding: 0,
        minHeight: 20,
        minWidth: 60
    },
    inputLabel: {
        fontSize: 15
    }
});

class EditableRulesCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ruleAmountMin: props.ruleAmountMin,
            ruleAmountMax: props.ruleAmountMax,
            displayRuleAmountMin: (props.ruleAmountMin / 100).toFixed(2),
            displayRuleAmountMax: (props.ruleAmountMax / 100).toFixed(2),
            pattern: props.pattern,
            isSaving: false,
            isDeleting: false,
        };
    }

    save() {
        this.setState({isSaving: true});

        const data = {
            "amountMin": this.state.ruleAmountMin,
            "amountMax": this.state.ruleAmountMax,
            "pattern": this.state.pattern,
            "categoryId": this.props.categoryId
        };

        const that = this;
        saveRule(data, this.props.ruleId).then(() => that.props.onChangeAction());
    }

    delete() {
        this.setState({isDeleting: true});

        let axiosPromise = API.delete(PATH_RULES + "/" + this.props.ruleId);

        const that = this;
        axiosPromise.then(() => that.props.onChangeAction());
    }

    cancel() {
        this.props.cancelButtonAction();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleAmountChange = (name, displayName) => event => {
        this.setState({
            [name]: (event.target.value * 100).toFixed(0),
            [displayName]: event.target.value,
        });
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        {this.showTextInputs()}
                    </CardContent>
                    <CardActions>
                        <ControlButtons onClickSave={() => this.save()}
                                        onClickCancel={() => this.cancel()}
                                        onClickDelete={() => this.delete()}
                                        isSaveDisabled={this.state.isSaving}
                                        isDeleteDisabled={this.props.ruleId === 0 || this.state.isDeleting}
                                        size="small"
                                        buttonClassName={classes.controlButtons}
                        />
                    </CardActions>
                </Card>
            </div>
        );
    }

    showTextInputs() {
        const {classes} = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel margin="dense" className={classes.inputLabel}>Pattern</InputLabel>
                        <Input
                            inputProps={{
                                autoComplete: 'off', autoCorrect: 'off', autoCapitalize: 'off', spellCheck: 'false',
                            }}
                            margin="dense"
                            value={this.state.pattern}
                            onChange={this.handleChange('pattern')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel margin="dense" className={classes.inputLabel}>Min Amount</InputLabel>
                        <Input
                            type="number"
                            margin="dense"
                            value={this.state.displayRuleAmountMin}
                            onChange={this.handleAmountChange('ruleAmountMin', 'displayRuleAmountMin')}
                            startAdornment={<InputAdornment position="start">£</InputAdornment>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel margin="dense" className={classes.inputLabel}>Max Amount</InputLabel>
                        <Input
                            type="number"
                            margin="dense"
                            value={this.state.displayRuleAmountMax}
                            onChange={this.handleAmountChange('ruleAmountMax', 'displayRuleAmountMax')}
                            startAdornment={<InputAdornment position="start">£</InputAdornment>}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
}

EditableRulesCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    ruleAmountMin: PropTypes.number.isRequired,
    ruleAmountMax: PropTypes.number.isRequired,
    onChangeAction: PropTypes.func.isRequired,
    cancelButtonAction: PropTypes.func.isRequired,
    pattern: PropTypes.string.isRequired,
    ruleId: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
};

export default withStyles(styles, {withTheme: true})(EditableRulesCard);
