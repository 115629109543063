export const flattenCategories = categories => {
    let flattenedCategories = [];
    categories.forEach(parentCategory => {
        flattenedCategories.push({...parentCategory});
        if (parentCategory.children !== null) {
            flattenedCategories.push(...parentCategory.children.map(childCategory => {
                return {...childCategory, name: `${parentCategory.name} » ${childCategory.name}`};
            }));
        }
    });
    return flattenedCategories;
};

function includesSearch(string, search) {
    return string.toLowerCase().includes(search);
}

function containsRuleWithSearch(filteredRules, cat) {
    return filteredRules.find(r => r.categoryId === cat.id);
}

function filterCategoriesByRulePattern(categories, rules, search) {
    const filteredRules = rules.filter(r => includesSearch(r.pattern, search));
    return categories.filter(cat => containsRuleWithSearch(filteredRules, cat) || cat.children.some(ch => containsRuleWithSearch(filteredRules, ch)));
}

export const sortAndFilterCategoriesBySearchInRulePattern = (categories, rules, search) => {
    let cat = search === "" ? [...categories] : filterCategoriesByRulePattern(categories, rules, search);
    return cat.sort((a, b) => a.name.localeCompare(b.name));
};

function filterCategoriesByCategoryName(categories, search) {
    return categories.filter(cat => cat.name.toLowerCase().includes(search) || cat.children.some(ch => ch.name.toLowerCase().includes(search)));
}

export const sortAndFilterCategoriesBySearchInCategoryName = (categories, search) => {
    let cat = search === "" ? [...categories] : filterCategoriesByCategoryName(categories, search);
    return cat.sort((a, b) => a.name.localeCompare(b.name));
};