import React, {Fragment, useEffect, useState} from 'react';
import ImportSection from "./ImportSection";
import {PATH_ACCOUNTS_MINE} from "../config";
import {useDataApi} from '../api';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import Uploads from "./Uploads";
import {changeTitle} from "../utils/utils";

function Import() {
    useEffect(() => changeTitle('Import'), []);

    const [state] = useDataApi(PATH_ACCOUNTS_MINE, {accounts: []});
    const [lastChanged, setLastChanged] = useState(() => new Date().getTime());

    if (state.isLoading) {
        return <Typography>Loading ...</Typography>;
    }

    if (state.error) {
        return <Typography>Could not load accounts. {state.error}</Typography>;
    }

    return (
        <Fragment>
            <Typography variant="h4" gutterBottom>Import</Typography>
            <Grid container spacing={3}>
                {state.data.accounts.length
                    ? state.data.accounts.map(account =>
                        <Grid item xs={12} sm={6} md={4} lg={3} key={account.id}>
                            <ImportSection accountId={account.id}
                                           accountName={account.name}
                                           accountParser={account.parser}
                                           onChangeAction={() => setLastChanged(new Date().getTime())}/>
                        </Grid>)
                    : <Typography variant="subtitle1">You do not have any accounts.</Typography>}
                <Grid item xs={12}>
                    <Uploads lastChanged={lastChanged}/>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Import;
