import React, {useState} from 'react';
import Dropzone from "react-dropzone";
import Typography from "@material-ui/core/Typography/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import API, {useDataApi} from '../api';
import InfoIcon from '@material-ui/icons/Info';
import {PATH_PARSER_INFO, PATH_UPLOADS} from "../config";
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles(theme => ({
    dropzone: {
        height: 100,
        border: '2px dashed grey'
    },
    dropzoneContent: {
        height: "100px"
    },
    instructions: {
        paddingRight: theme.spacing(2)
    }
}));

function ImportSection(props) {
    const classes = useStyles();

    const [instructionsText] = useDataApi(getUrl(), "");
    const [successStatusMessages, setSuccessStatusMessages] = useState([]);
    const [errorStatusMessages, setErrorStatusMessages] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    function getUrl() {
        const qs = new URLSearchParams({"name": props.accountParser});
        return `${PATH_PARSER_INFO}?${qs}`;
    }

    const getEncodedFileAndUpload = (acceptedFiles) => {
        setIsUploading(true);

        if (acceptedFiles <= 0) {
            setErrorStatusMessages(["Incorrect file format. Please upload a CSV file."]);
            setIsUploading(false);
            return;
        }

        let count = 0;
        let encodedFiles = [];

        function getBase64(file, successCallback, errorCallback) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                encodedFiles.push(
                    {
                        "name": file.name,
                        "type": file.type,
                        "content": reader.result.replace('data:' + file.type + ';base64,', '')
                    });
                count++;

                if (count === acceptedFiles.length) {
                    successCallback(encodedFiles);
                }

            };
            reader.onerror = function (error) {
                errorCallback({errorMessage: error.message});
            };
        }

        for (let file of acceptedFiles) {
            getBase64(file, (encodedFiles) => uploadFiles(encodedFiles), (error) => setErrorStatusMessages(error.message));
        }
    };

    const listStatusMessages = (isSuccess) => {
        const statusMessages = isSuccess ? successStatusMessages : errorStatusMessages;
        return statusMessages.map((message, index) =>
            <ListItem key={index}>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography style={{overflowX: "auto", color: isSuccess ? "green" : "red"}}>
                            {message}
                        </Typography>
                    }/>
            </ListItem>
        );
    };

    const uploadFiles = (encodedFiles) => {
        let data = encodedFiles.map(file => (
            {
                "accountId": props.accountId,
                "filename": file.name,
                "contentType": file.type,
                "contentBase64": file.content
            })
        );

        let axiosPromise = API.post(PATH_UPLOADS, data);

        axiosPromise
            .then(
                result => {
                    let successMessages = [];
                    let errorMessages = [];

                    for (let file of result.data.results) {
                        if (file.error) {
                            errorMessages.push("There was an error processing " + file.upload.filename + ". Error: " + file.error);
                        } else {
                            successMessages.push(file.numTransactions + " transactions were added for " + file.upload.filename);
                        }
                    }

                    setSuccessStatusMessages(successMessages);
                    setErrorStatusMessages(errorMessages);
                    setIsUploading(false);

                    props.onChangeAction();
                }
            )
            .catch(
                error => {
                    setErrorStatusMessages(error.message);
                    setIsUploading(false);
                }
            );
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Card>
                <CardHeader title={props.accountName} action={
                    <>
                        <IconButton aria-label="How to prepare CSVs"
                                    onClick={handleClick}
                                    disabled={!instructionsText.data}>
                            <InfoIcon/>
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <ol>
                                {instructionsText.data.split('\n').map((instruction, index) =>
                                    <Typography className={classes.instructions} key={index}>
                                        <li>{instruction}</li>
                                    </Typography>
                                )}
                            </ol>
                        </Popover>
                    </>
                }/>
                <CardContent>
                    <Dropzone
                        accept="text/csv"
                        onDrop={(accepted) => {
                            getEncodedFileAndUpload(accepted);
                        }}
                        activeStyle={{
                            height: 100,
                            border: '2px dashed grey',
                            backgroundColor: 'lightcyan'
                        }}
                        className={classes.dropzone}
                    >
                        <Grid container direction="column"
                              alignItems="center"
                              justifyContent="center"
                              className={classes.dropzoneContent}>
                            <Grid item>
                                {
                                    isUploading ?
                                        <CircularProgress/>
                                        :
                                        <Typography variant="subtitle1" align="center" style={{color: "grey"}}>
                                            Drop CSV Files
                                        </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Dropzone>
                    <List>
                        {!isUploading && listStatusMessages(false)}
                        {!isUploading && listStatusMessages(true)}
                    </List>
                </CardContent>
            </Card>
        </div>
    );
}

ImportSection.propTypes = {
    accountId: PropTypes.number.isRequired,
    accountName: PropTypes.string.isRequired,
    accountParser: PropTypes.string.isRequired,
    onChangeAction: PropTypes.func.isRequired
};

export default ImportSection;