import React from 'react';
import AddComponentToggle from "../UtilComponents/AddComponentToggle";
import EditableRulesCard from "./EditableRulesCard";
import Typography from "@material-ui/core/Typography/Typography";
import EditToggle from "../UtilComponents/EditToggle";
import ReadOnlyRulesCard from "./ReadOnlyRulesCard";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: "1.1rem",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }
}));

function CategoriesRules(props) {
    const classes = useStyles();

    const getHeading = () => {
        if (props.category.parentId > 0) {
            return <Typography className={classes.heading} variant="h5">{props.category.name}</Typography>;
        }
        return "";
    };

    const getChildren = () => {
        if (props.category.children) {
            return props.category.children.map(category => <CategoriesRules
                    key={category.id}
                    category={category}
                    categoryIdToRuleMap={props.categoryIdToRuleMap}
                    onChangeAction={props.onChangeAction}
                    searchValue={props.searchValue}
                />
            );
        }
        return "";
    };

    const showRules = () => {
        const rules = props.categoryIdToRuleMap[props.category.id];
        if (!rules) {
            return <Grid item xs={2}>
                <Typography variant="body2">No Rules</Typography>
            </Grid>;
        }
        return rules.map(rule =>
            <Grid item xs={6} sm={4} key={rule.id}>
                <EditToggle
                    editComponent={
                        <EditableRulesCard
                            ruleId={rule.id}
                            ruleAmountMin={rule.amountMin}
                            ruleAmountMax={rule.amountMax}
                            pattern={rule.pattern}
                            categoryId={props.category.id}
                            onChangeAction={props.onChangeAction}
                        />}
                    readOnlyComponent={
                        <ReadOnlyRulesCard
                            ruleId={rule.id}
                            ruleAmountMin={rule.amountMin}
                            ruleAmountMax={rule.amountMax}
                            pattern={rule.pattern}
                            searchValue={props.searchValue}
                        />}
                />
            </Grid>
        );
    };

    return <div className={classes.root}>
        {getHeading()}
        <Grid container direction="row" spacing={6}>
            {showRules()}
            <Grid item xs={6} sm={4}>
                <AddComponentToggle componentToAdd={
                    <EditableRulesCard
                        ruleId={0}
                        ruleAmountMin={0}
                        ruleAmountMax={0}
                        pattern={""}
                        categoryId={props.category.id}
                        onChangeAction={props.onChangeAction}/>
                }
                />
            </Grid>
        </Grid>
        {getChildren()}
    </div>;
}

CategoriesRules.propTypes = {
    category: PropTypes.object.isRequired,
    categoryIdToRuleMap: PropTypes.object.isRequired,
    onChangeAction: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
};

export default CategoriesRules;
