import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";
import * as PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    highlight: {
        backgroundColor: '#FFFF00'
    }
}));

function Highlight(props) {
    const classes = useStyles();

    const matches = match(props.input, props.highlightValue);
    const parts = parse(props.input, matches);

    const getClassName = (shouldHighlight) => {
        if (shouldHighlight) {
            if (props.highlightStyle) {
                return props.highlightStyle;
            }
            return classes.highlight;
        }
        return '';
    };

    return <>
        {parts.map((part, index) =>
            <span key={String(index)}
                  className={getClassName(part.highlight)}>
                {props.removeNonBreakingSpace ? part.text.replace(/ /g, '\xa0') : part.text}
            </span>)
        }
    </>;

}

Highlight.propTypes = {
    input: PropTypes.string.isRequired,
    highlightValue: PropTypes.string.isRequired,
    highlightStyle: PropTypes.string,
    removeNonBreakingSpace: PropTypes.bool
};

export default Highlight;