import React, {Component} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button/Button";
import Dialog from '@material-ui/core/Dialog';
import PropTypes from "prop-types";

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null
        };
    }

    componentDidCatch(error) {
        this.setState({errorMessage: error.message});
    }

    handleCloseErrorDialog() {
        this.setState({errorMessage: null});
    }

    render() {
        if (this.state.errorMessage) {
            return (
                <div>
                    <Dialog open onClose={() => this.handleCloseErrorDialog}>
                        <DialogTitle>Oops</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Error: {this.state.errorMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleCloseErrorDialog()} color="primary">
                                close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element
};

export default ErrorBoundary;
