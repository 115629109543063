import {makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import * as PropTypes from "prop-types";

const useSearchBoxStyles = makeStyles(theme => ({
    control: {
        marginRight: theme.spacing(2),
        verticalAlign: 'text-bottom'
    },
}));

function SearchBox(props) {
    const classes = useSearchBoxStyles();

    const [value, setValue] = useState(props.value);

    const handleKeyDown = event => {
        if (props.onKeyDown && event.key === 'Enter') {
            props.onKeyDown(value);
        }
    };
    const handleKeyUp = () => {
        if (props.onKeyUp) {
            props.onKeyUp(value);
        }
    };

    const handleClear = () => {
        props.value && props.onClear();
        setValue("");
    };

    return (
        <FormControl className={classes.control}>
            <Input value={value}
                   inputProps={{
                       autoComplete: 'off', autoCorrect: 'off', autoCapitalize: 'off', spellCheck: 'false',
                   }}
                   onChange={(event) => setValue(event.target.value)}
                   onKeyDown={handleKeyDown}
                   onKeyUp={handleKeyUp}
                   placeholder="Search"
                   endAdornment={
                       <InputAdornment position="end">
                           <IconButton disabled={!value.length} onClick={handleClear}><ClearIcon/></IconButton>
                       </InputAdornment>
                   }/>
        </FormControl>
    );
}

SearchBox.propTypes = {
    value: PropTypes.string.isRequired,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onClear: PropTypes.func.isRequired,
};

export default SearchBox;