import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Highlight from "../UtilComponents/Highlight";

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.background.default,
    },
    cardPadding: {
        padding: theme.spacing(2),
    },
    discard: {
        color: 'grey',
    },
}));

function ReadOnlyCategoryCard(props) {
    const classes = useStyles();
    return <Card className={classes.card}>
        <CardHeader className={classes.cardPadding}
                    action={
                        <IconButton onClick={props.editButtonAction ? props.editButtonAction : () => {}}>
                            <EditIcon fontSize="small"/>
                        </IconButton>
                    }
                    title={<Highlight input={props.categoryName}
                                      highlightValue={props.searchValue}/>}
                    titleTypographyProps={{
                        variant: "subtitle1",
                        className: props.discard ? classes.discard : ''
                    }}
        />
    </Card>;
}

ReadOnlyCategoryCard.propTypes = {
    editButtonAction: PropTypes.func,
    categoryName: PropTypes.string.isRequired,
    discard: PropTypes.bool.isRequired,
    searchValue: PropTypes.string.isRequired,
};

export default ReadOnlyCategoryCard;
