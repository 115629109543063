import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from "@material-ui/core/Avatar/Avatar";
import Chip from "@material-ui/core/Chip/Chip";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        display: "inline-block",
        marginRight: theme.spacing(1),
    }
}));

const ReadOnlyAccountCard = (props) => {
    const classes = useStyles();

    return <Card>
        <CardHeader
            action={<IconButton onClick={() => props.editButtonAction()}><EditIcon/></IconButton>}
            title={<>
                <Avatar className={classes.small}
                        alt={props.account.parser}
                        src={`bank-icons/${props.account.parser.toLowerCase()}.png`}/>
                {props.account.name}
            </>}
            subheader={`Parser: ${props.account.parser}`}
        />
        <CardContent>
            {props.account.users.map(user => <Chip key={user.id}
                                                   avatar={<Avatar alt={user.name} src={user.pictureUrl}/>}
                                                   label={user.name}
                                                   style={{marginLeft: 8, marginBottom: 8}}/>
            )}
        </CardContent>
    </Card>;
};

ReadOnlyAccountCard.propTypes = {
    editButtonAction: PropTypes.func,
    account: PropTypes.shape({
        name: PropTypes.string.isRequired,
        parser: PropTypes.string.isRequired,
        users: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            pictureUrl: PropTypes.string,
        })).isRequired
    }).isRequired
};

export default ReadOnlyAccountCard;