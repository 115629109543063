import API from "../api";
import {PATH_TRANSACTIONS, PATH_TRANSACTIONS_MULTI} from "../config";

const getAmount = (i, amount, users, transactionId) => {
    const amountPerUser = Math.floor(Math.abs(amount) / users.length);
    const remainder = Math.abs(amount) % users.length;
    const specialIndex = transactionId % users.length;
    return (i === specialIndex) ? amountPerUser + remainder : amountPerUser;
};

export const createDefaultExpensesWithRandomId = (amount, users, transactionId, categoryId = 0) => {
    return users.map((user, i) => {
        return {
            randomId: Math.random(),
            userId: user.id,
            amount: getAmount(i, amount, users, transactionId),
            categoryId,
        };
    });
};

export const createDefaultExpenses = (amount, users, transactionId, categoryId = 0) => {
    return users.map((user, i) => {
        return {
            userId: user.id,
            amount: getAmount(i, amount, users, transactionId),
            categoryId,
        };
    });
};

export const saveExpenses = (transactionId, expenses) => {
    const expensesRequest = expenses.map(e => {
        const {userId, amount, categoryId} = e;
        return {userId, amount, categoryId};
    });
    return API.post(`${PATH_TRANSACTIONS}/${transactionId}/expenses`, expensesRequest);
};

export const multiSaveTransactionExpenses = (transactionExpenses) => {
    return API.post(`${PATH_TRANSACTIONS_MULTI}`, Object.fromEntries(transactionExpenses));
};