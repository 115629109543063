import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {formatCcy} from "../utils/utils";

const useStyles = makeStyles(theme => ({
    chip: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.25),
        marginBottom: theme.spacing(0.25),
    },
}));

function ExpenseChips(props) {
    const classes = useStyles();

    const correctTotal = props.expenses.reduce((total, e) => total + e.amount, 0) === Math.abs(props.transactionAmount);
    const singleChip = new Set(props.expenses.map(e => e.categoryId)).size === 1 && correctTotal;

    if (singleChip) {
        return <Chip onDoubleClick={() => props.onSingleChipEdit()} size="small"
                     label={props.categories[props.expenses[0].categoryId].name}/>;
    } else {
        return props.expenses.map(e => <Chip
            size="small"
            color={correctTotal ? "default" : "secondary"}
            variant={correctTotal ? "default" : "outlined"}
            onDoubleClick={props.onMultiChipEdit}
            className={classes.chip}
            key={e.id}
            label={`${props.categories[e.categoryId].name}: ${formatCcy(e.amount / 100)}`}/>);
    }
}

ExpenseChips.propTypes = {
    expenses: PropTypes.array.isRequired,
    transactionAmount: PropTypes.number.isRequired,
    categories: PropTypes.object.isRequired,
    onSingleChipEdit: PropTypes.func.isRequired,
    onMultiChipEdit: PropTypes.func.isRequired,
};

export default ExpenseChips;