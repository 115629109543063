import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddIcon from '@material-ui/icons/Add';
import Grid from "@material-ui/core/Grid";
import CategoryPicker from "./CategoryPicker";
import EditExpensesDialog from "./EditExpensesDialog";
import Optional from "../UtilComponents/Optional";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ExpenseChips from "./ExpenseChips";
import {createDefaultExpenses, createDefaultExpensesWithRandomId, saveExpenses} from "./ExpensesUtils";
import {Box} from "@mui/material";

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '50%',
    },
    iconButtonRoot: {
        padding: theme.spacing(1),
        height: theme.spacing(5),
        width: theme.spacing(5),
    },
    warning: {
        color: theme.palette.error.main,
        margin: theme.spacing(0.5),
    },
    expensesButtons: {
        marginTop: '1px',
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        transform: 'translate(-100%, 0)',
    },
}));

function ExpensesCell({
                          transactionId,
                          transactionAmount,
                          transactionDescription,
                          expenses,
                          users,
                          categories,
                          categoryPickerId,
                          popperAnchorEl,
                          isMouseOver,
                          forceMouseOut,
                          handleSingleSaveSuccess,
                      }) {
    const classes = useStyles();

    const [isEditExpensesDialogOpen, setIsEditExpensesDialogOpen] = useState(false);
    const [autofocusCategoryPicker, setAutofocusCategoryPicker] = useState(false);
    const [error, setError] = useState(null);

    // used for double click behaviour
    const [tempExpenses, setTempExpenses] = useState(expenses);
    const [expensesBackup, setExpensesBackup] = useState([...(expenses)]);

    useEffect(() => setTempExpenses(expenses), [expenses]);

    const handleEditButtonClicked = function () {
        forceMouseOut();
        setIsEditExpensesDialogOpen(true);
    };

    const handleEditMultiChip = function () {
        forceMouseOut();
        setIsEditExpensesDialogOpen(true);
    };

    const handleEditSingleChip = function () {
        forceMouseOut();
        setAutofocusCategoryPicker(true);
        setTempExpenses([]);
        setExpensesBackup([...tempExpenses]);
    };

    const handleEditCategoryCancel = function () {
        setTempExpenses([...expensesBackup]);
        setAutofocusCategoryPicker(false);
    };

    const handleDialogClose = function () {
        setIsEditExpensesDialogOpen(false);
    };

    const onSave = (expenses) => {
        saveExpenses(transactionId, expenses)
            .then((result) => {
                setIsEditExpensesDialogOpen(false);
                handleSingleSaveSuccess(transactionId, result.data.expenses);
                setTempExpenses(result.data.expenses);
                setError(null);
                setAutofocusCategoryPicker(false);
            })
            .catch(error => handleError(error.message));
    };

    const handleError = () => {
        setError(error);
        setIsEditExpensesDialogOpen(false);
    };

    const categoryPicker =
        <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
                <CategoryPicker dense categoryPickerId={categoryPickerId}
                                availableCategories={categories}
                                onSave={(categoryId) => onSave(createDefaultExpenses(transactionAmount, users, transactionId, categoryId))}
                                autoFocus={autofocusCategoryPicker}
                                onBlur={handleEditCategoryCancel}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton variant="contained" color="secondary" classes={{root: classes.iconButtonRoot}}
                            onClick={handleEditButtonClicked}>
                    <AddIcon fontSize="small"/>
                </IconButton>
            </Grid>
        </Grid>;

    const editIconPopper = <Popper open={isMouseOver} anchorEl={popperAnchorEl}
                                   popperOptions={{placement: 'right'}}>
        <Box className={classes.expensesButtons}>
            <IconButton variant="contained" color="secondary" classes={{root: classes.iconButtonRoot}}
                        onClick={handleEditButtonClicked}>
                <EditIcon fontSize="small"/>
            </IconButton>
        </Box>
    </Popper>;

    if (error) {
        return <Typography className={classes.warning}>{error}</Typography>;
    }

    return <>
        <Optional hidden={tempExpenses.length !== 0}>
            {categoryPicker}
        </Optional>
        <Optional hidden={tempExpenses.length === 0}>
            {editIconPopper}
            <ExpenseChips expenses={expenses}
                          transactionAmount={transactionAmount}
                          categories={categories}
                          onSingleChipEdit={handleEditSingleChip}
                          onMultiChipEdit={handleEditMultiChip}
            />
        </Optional>
        <EditExpensesDialog
            isDialogOpen={isEditExpensesDialogOpen}
            expenses={expenses.length ? expenses : createDefaultExpensesWithRandomId(transactionAmount, users, transactionId)}
            transactionId={transactionId}
            transactionAmount={transactionAmount}
            transactionDescription={transactionDescription}
            onClose={handleDialogClose}
            users={users}
            categories={categories}
            onSave={onSave}
        />
    </>;
}

ExpensesCell.propTypes = {
    transactionId: PropTypes.number.isRequired,
    transactionAmount: PropTypes.number.isRequired,
    transactionDescription: PropTypes.string.isRequired,
    expenses: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    categories: PropTypes.object.isRequired,
    categoryPickerId: PropTypes.string.isRequired,
    popperAnchorEl: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.instanceOf(Object),
    ]),
    isMouseOver: PropTypes.bool.isRequired,
    forceMouseOut: PropTypes.func.isRequired,
    handleSingleSaveSuccess: PropTypes.func.isRequired,
};

export default ExpensesCell;
